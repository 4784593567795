// Collective data of a single experiment
export class Experiment {
  constructor(
    // ID in database
    id = "",
    // ID of parent experiment
    parentId = "",
    // Title of experiment
    title = "-",
    // Additional text info on experiment
    note = "-",
    // Average of all predicted confidence values
    averageConfidence = 0,
    // Most frequently predicted scribe
    dominantScribe = new Scribe(),
    // Overview statistics about predicted scribes
    scribeConfidences = [],
    // Pages in experiment, grouped in codecies
    codecies = []
  ) {
    this.id = id;
    this.parentId = parentId;
    this.title = title;
    this.note = note;
    this.averageConfidence = averageConfidence;
    this.dominantScribe = dominantScribe;
    this.scribeConfidences = scribeConfidences;
    this.codecies = codecies;
  }
}

// Data of a scribe and all pages, that are identified for that scribe
export class ScribePageCollection {
  constructor(scribe = new Scribe(), codecies = []) {
    // Info of scribe
    this.scribe = scribe;
    // Relevant pages, grouped in codecies
    this.codecies = codecies;
  }
}

// Collection of pages from a single codex
export class Codex {
  constructor(id = "", name = "-", pages = []) {
    // ID in database
    this.id = id;
    // Name of codex
    this.name = name;
    // Pages related to codex
    this.pages = pages;
  }
}

// Individual page with optional info from the result of an experiment
export class Page {
  constructor(
    // ID in database
    id = "",
    // Name of page (page side + number)
    name = "-",
    // Link to picture of page
    imgLink = "",
    // Link to low-res picture of page
    prevLink = "",
    // Info on confirmed author scribe
    groundtruthScribe = new Scribe(),
    // ID of codex, the page is part of
    codexId = "",
    // Name of codex
    codexName = "-",
    // Prediction data for page
    experimentPrediction = new PageExperimentPrediction(),
    // Percentage based feedback made to page
    corrections = [],
    // Text based feedback made to page
    notes = []
  ) {
    this.id = id;
    this.name = name;
    this.imgLink = imgLink;
    this.prevLink = prevLink;
    this.groundtruthScribe = groundtruthScribe;
    this.codexId = codexId;
    this.codexName = codexName;
    this.experimentPrediction = experimentPrediction;
    this.corrections = corrections;
    this.notes = notes;
  }
}

// Result of a prediction from an experiment for a specific page
export class PageExperimentPrediction {
  constructor(probability = 0, predictionScribe = new Scribe()) {
    // Strength of prediction (0-100)
    this.probability = probability;
    // Predicted scribe
    this.predictionScribe = predictionScribe;
  }
}

// Helper class for averaging multiple predictions for a single scribe
export class AveragedPagePrediction {
  constructor(probabilitySum = 0, count = 0, predictionScribe = new Scribe()) {
    // Sum of predicted probabilities
    this.probabilitySum = probabilitySum;
    // Count of individual
    this.count = count;
    // Target scribe
    this.predictionScribe = predictionScribe;
  }
}

// Collective info on a single scribe
export class Scribe {
  constructor(
    // ID in database
    id = "",
    // Name of scribe
    name = "-",
    // Color of scribe (hex)
    backgroundColour = "FFFFFF",
    // Color texture of scribe (empty/"striped"/"dotted")
    backgroundType = "",
    // Flag for whether scribe appears in selected experiment (either as prediction or groundtruth)
    inExperiment = false
  ) {
    this.id = id;
    this.name = name;
    this.backgroundColour = backgroundColour;
    this.backgroundType = backgroundType;
    this.inExperiment = inExperiment;
  }
}

// Expanded version of Scribe class, used for averaging appearance over an experiment
export class PredictionScribe {
  constructor(
    // ID in database
    id = "",
    // Name of scribe
    name = "-",
    // Color of scribe (hex)
    backgroundColour = "FFFFFF",
    // Color texture of scribe (empty/"striped"/"dotted")
    backgroundType = "",
    // Averaged value for prediction confidence
    averageConfidence = 0,
    // Counter for appearance
    count = 0
  ) {
    this.id = id;
    this.name = name;
    this.backgroundColour = backgroundColour;
    this.backgroundType = backgroundType;
    this.averageConfidence = averageConfidence;
    this.count = count;
  }
}

// Identifying info of a user, who submits feedback
export class Corrector {
  constructor(id = "", name = "-", role = "user") {
    // ID in database
    this.id = id;
    // Username
    this.name = name;
    // Access rights
    this.role = role;
  }
}

// Feedback made by a user for a page, suggesting possibilities for different scribes as author
export class PageCorrection {
  constructor(
    // List of corrections
    corrections = [],
    // Date of creation
    date = "0000-00-00",
    // Time of creation
    time = "00:00",
    // User info of corrector
    corrector = new Corrector()
  ) {
    this.corrections = corrections;
    this.date = date;
    this.time = time;
    this.corrector = corrector;
  }
}

// Item for holding data on the confidence of a feedback
export class IndividualCorrection {
  constructor(scribe = new Scribe(), confidence = 0) {
    // Scribe info
    this.scribe = scribe;
    // Confidence of corrector for the selected scribe
    this.confidence = confidence;
  }
}

// Feedback made by a user for a page, adding text based info
export class PageNote {
  constructor(
    // Comment on page
    message = "placeholderMessage",
    // Date of creation
    date = "0000-00-00",
    // Time of creation
    time = "00:00",
    // User info of corrector
    corrector = new Corrector()
  ) {
    this.message = message;
    this.date = date;
    this.time = time;
    this.corrector = corrector;
  }
}

// Feedback made by a user for a scribe, adding text based info
export class ScribeComment {
  constructor(
    // Comment on scribe
    message = "placeholderMessage",
    // Date of creation
    date = "0000-00-00",
    // Time of creation
    time = "00:00",
    // User info of corrector
    corrector = new Corrector()
  ) {
    this.message = message;
    this.date = date;
    this.time = time;
    this.corrector = corrector;
  }
}
