import React, { useState, useEffect } from "react";
// Popups
import { Report } from "notiflix/build/notiflix-report-aio";

// Param: Bool to signal to the navigation-bar to update
const Countdown = ({ logoutFunc }) => {
  // Seconds from timeout (in seconds)
  const [timeoutWarningTime] = useState(10 * 60);

  // Expiration date
  const countDownDate = Date.parse(localStorage.getItem("logoutDate"));

  // Countdown (in seconds)
  const [countDown, setCountDown] = useState(
    (countDownDate - new Date().getTime()) / 1000
  );

  // Timer cycle
  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(Math.round((countDownDate - new Date().getTime()) / 1000));
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  useEffect(() => {
    if (countDown < 0 && localStorage.getItem("userLoggedIn")) {
      localStorage.setItem("userLoggedIn", false);
      logoutFunc();
    }
  }, [countDown]);

  // Returning countdown timer
  return (
    <div>
      <span
        style={{
          color: countDown < timeoutWarningTime && "red",
        }}
      >
        {Math.floor((countDown / (60 * 60)) % 24).toLocaleString("en-UK", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}{" "}
        :{" "}
        {Math.floor((countDown / 60) % 60).toLocaleString("en-UK", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}{" "}
        :{" "}
        {Math.floor(countDown % 60).toLocaleString("en-UK", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </span>
    </div>
  );
};

export default Countdown;
