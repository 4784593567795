import React from "react";
// Variable handling
import { useState } from "react";
import { useContext } from "react";
// Bootstrap components
import { Row, Col, Container, Card, CloseButton } from "react-bootstrap";
// Coloring method
import { scribeColor } from "../utilities/autoCssStyling";
// Slider component
import Slider from "@mui/material/Slider";
// Reference to color theme
import { ThemeContext } from "../context/ThemeContext";

const Feedback = ({
  // Selected scribe
  scribe,
  // Function to delete correction
  deleteHandler,
  // Function to update correction's confidence
  updateHandler,
}) => {
  // Color theme
  const { darkMode } = useContext(ThemeContext);

  // Number value
  const [field, setField] = useState(0);
  const updateField = (event, newValue) => {
    validateField(newValue);
  };
  // Slider labels
  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 20,
      label: "20%",
    },
    {
      value: 40,
      label: "40%",
    },
    {
      value: 60,
      label: "60%",
    },
    {
      value: 80,
      label: "80%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  // Confidence validation
  const validateField = (value) => {
    if (0 <= value && value <= 100) {
      updateHandler(scribe.id, value);
      setField(value);
    } else {
      updateHandler(scribe.id, 0);
      setField(0);
    }
  };

  return (
    <Card className="mb-3" border={`${darkMode && "dark"}`}>
      <Card.Header style={scribeColor(scribe)}>
        <Row>
          {/* Header */}
          <Col className="text-start fw-bold">{scribe.name}</Col>
          <Col className="d-flex justify-content-end">
            <CloseButton
              onClick={() => deleteHandler(scribe.id)}
              variant={scribeColor(scribe.id).color}
            ></CloseButton>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className={`${darkMode && "darkmode-secondary"}`}>
        <Container>
          <Row>
            {/* Slider */}
            <Col>
              <Slider
                value={field}
                onChange={updateField}
                min={0}
                max={100}
                step={5}
                valueLabelDisplay="auto"
                marks={marks}
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default Feedback;
