import React from "react";

// Variable handling
import { useContext, useState } from "react";
// Bootstrap components
import { Table, Offcanvas, Card } from "react-bootstrap";
// Reference to color theme
import { ThemeContext } from "../context/ThemeContext";
// Coloring methods
import { scribeColor, confidenceColor } from "../utilities/autoCssStyling";
// Icons
import { BiNotepad } from "react-icons/bi";
import { VscKey } from "react-icons/vsc";
import { AiOutlineUser } from "react-icons/ai";
import { FaPercent } from "react-icons/fa";

const ExperimentInfo = ({
  // Component that triggers the interaction
  children,
  // Experiment
  experiment,
  // Grayscale coloring points
  grayscalePoints,
}) => {
  // Color theme
  const { darkMode } = useContext(ThemeContext);

  const [showInfo, setShowInfo] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setShowInfo(true);
        }}
      >
        {children}
      </div>

      {experiment && (
        <Offcanvas
          show={showInfo}
          onHide={() => {
            setShowInfo(false);
          }}
          scroll={true}
          backdrop={true}
        >
          <Offcanvas.Header
            closeButton
            className={`${darkMode && "darkmode-primary"}`}
          >
            <Offcanvas.Title>{experiment.title}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body
            className={`${darkMode && "darkmode-secondary"} overflow-auto`}
          >
            {/* General Info */}
            <Card border={`${darkMode && "dark"}`} className="mb-3">
              <Card.Header
                className={`p-2 px-3 ${darkMode && "darkmode-primary"}`}
              >
                <h5 className="m-0">General Information</h5>
              </Card.Header>
              <Card.Body
                className={`p-0 d-flex flex-row flex-wrap ${
                  darkMode && "darkmode-tertiary"
                }`}
              >
                <Table
                  className="mb-0"
                  striped
                  bordered
                  variant={`${darkMode && "dark"}`}
                >
                  <tbody>
                    {/* ID field */}
                    <tr>
                      <td className="fw-bold">
                        <VscKey size={"2em"} /> Id:
                      </td>
                    </tr>
                    <tr>
                      <td>{experiment.id}</td>
                    </tr>
                    {/* Note field */}
                    <tr>
                      <td className="fw-bold">
                        <BiNotepad size={"2em"} /> Notes:
                      </td>
                    </tr>
                    <tr>
                      <td>{experiment.note}</td>
                    </tr>
                    {/* Average confidence field */}
                    <tr>
                      <td className="fw-bold">
                        <FaPercent size={"2em"} /> Average confidence:
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={confidenceColor(
                          experiment.averageConfidence,
                          grayscalePoints
                        )}
                      >
                        {experiment.averageConfidence}%
                      </td>
                    </tr>
                    {/* Most dominant hand field */}
                    <tr>
                      <td className="fw-bold">
                        <AiOutlineUser size={"2em"} /> Dominant hand:
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="fw-bold"
                        style={scribeColor(experiment.dominantScribe)}
                      >
                        {experiment.dominantScribe.name}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            {/* Scribe confidence averages */}
            <Card border={`${darkMode && "dark"}`}>
              {/* Title */}
              <Card.Header
                className={`p-2 px-3 ${darkMode && "darkmode-primary"}`}
              >
                <h5 className="m-0">Average Scribe Confidences</h5>
              </Card.Header>
              <Card.Body
                className={`p-0 d-flex flex-row flex-wrap ${
                  darkMode && "darkmode-tertiary"
                }`}
              >
                <Table
                  className="text-center mb-0 fw-bold"
                  bordered
                  variant={`${darkMode && "dark"}`}
                >
                  <tbody>
                    {experiment.scribeConfidences.map((scribe, index) => {
                      return (
                        <tr key={index}>
                          <td style={scribeColor(scribe)}>{scribe.name}</td>
                          <td
                            style={confidenceColor(
                              scribe.averageConfidence,
                              grayscalePoints
                            )}
                          >
                            {scribe.averageConfidence} %
                          </td>
                          <td>{scribe.count}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default ExperimentInfo;
