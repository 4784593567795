// Variable handling
import React, { useState, useEffect, useContext } from "react";

// Page swicht handling
import { useHistory } from "react-router-dom";

// React bootstrap components
import {
  Row,
  Col,
  Button,
  Card,
  Accordion,
  Container,
  Table,
  DropdownButton,
} from "react-bootstrap";

// API calls
import { APIGetAllExperiments } from "../utilities/apiCalls";

// Custom accordion toggle
import CustomAccordionToggle from "../components/CustomAccordionToggle";

// Icons
import { MdOutlineUpdate, MdOutlineQueryBuilder } from "react-icons/md";
import { BiEdit, BiNotepad } from "react-icons/bi";
import { VscKey } from "react-icons/vsc";

// Background Img
import libraryImg from "../assets/library.jpg";

// CSS
import "../assets/Editor.css";

// Reference to color theme
import { ThemeContext } from "../context/ThemeContext";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

export default function Experiments() {
  let history = useHistory();

  // List of experiments
  const [experiments, setExperiments] = useState([]);

  // Selected Filter
  const [filter, setFilter] = useState("");

  // Color theme
  const { darkMode } = useContext(ThemeContext);

  // Getting all experiments
  useEffect(() => {
    SetupExperiments();
  }, []);

  const SetupExperiments = async () => {
    setExperiments(await APIGetAllExperiments());
  };

  // List sorting
  const SortExperiments = (sortingType) => {
    const tempArr = [...experiments];

    switch (sortingType) {
      case "title":
        tempArr.sort((a, b) => {
          if (a.title > b.title) return 1;
          if (a.title < b.title) return -1;
          else return 0;
        });
        setExperiments(tempArr);
        setFilter("title");

        break;
      case "id":
        tempArr.sort((a, b) => {
          if (a.id > b.id) return 1;
          if (a.id < b.id) return -1;
          else return 0;
        });
        setExperiments(tempArr);
        setFilter("id");

        break;
      case "creationDate":
        tempArr.sort((a, b) => {
          var adate = new Date(a.created_at);
          var bdate = new Date(b.created_at);
          if (adate > bdate) return -1;
          if (adate < bdate) return 1;
          else return 0;
        });
        setExperiments(tempArr);
        setFilter("creationDate");

        break;
      case "updateDate":
        tempArr.sort((a, b) => {
          var adate = new Date(a.updated_at);
          var bdate = new Date(b.updated_at);
          if (adate > bdate) return -1;
          if (adate < bdate) return 1;
          else return 0;
        });
        setExperiments(tempArr);
        setFilter("updateDate");

        break;
      default:
    }
  };

  // Selecting experiment
  const selectExperiment = async (
    experimentID,
    experimentTitle,
    experimentNote
  ) => {
    // Set Id in localstorage
    localStorage.setItem("experimentId", experimentID);
    localStorage.setItem("experimentTitle", experimentTitle);
    localStorage.setItem("experimentNote", experimentNote);
    // Switch page
    history.push("/editor");
  };

  // Active key for the accordion
  const [activeAccordItems, setActiveAccordItems] = useState([]);

  return (
    <Container
      fluid
      style={{
        backgroundImage: `url(${libraryImg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Row>
        <Col
          className="d-flex justify-content-center"
          style={{ minHeight: "95vh" }}
        >
          <Card
            className="my-4"
            style={{ width: "80%" }}
            border={`${darkMode && "dark"}`}
          >
            {/* Title */}
            <Card.Header
              className={`text-start ${darkMode && "darkmode-primary"}`}
              type="text"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h1>Experiments</h1>

              <DropdownButton title={"Sort by"} variant="info" className="my-2">
                <DropdownItem
                  onClick={() => SortExperiments("title")}
                  active={filter == "title"}
                >
                  Title
                </DropdownItem>
                <DropdownItem
                  onClick={() => SortExperiments("id")}
                  active={filter == "id"}
                >
                  ID
                </DropdownItem>
                <DropdownItem
                  onClick={() => SortExperiments("creationDate")}
                  active={filter == "creationDate"}
                >
                  last Created
                </DropdownItem>
                <DropdownItem
                  onClick={() => SortExperiments("updateDate")}
                  active={filter == "updateDate"}
                >
                  last Modified
                </DropdownItem>
              </DropdownButton>
            </Card.Header>

            {/* Experiment list */}
            <Card.Body className={`p-2 ${darkMode && "darkmode-secondary"}`}>
              <Accordion defaultActiveKey={[]}>
                {experiments.map((experiment, index) => {
                  if (experiment.title) {
                    return (
                      <Card
                        key={index}
                        className="mb-2"
                        border={`${darkMode && "dark"}`}
                      >
                        {/* Experiment title */}
                        <CustomAccordionToggle
                          eventKey={index}
                          activeItems={activeAccordItems}
                          setActiveItems={setActiveAccordItems}
                          canOpenMultiple={false}
                        >
                          <h5 className="m-1">{experiment.title}</h5>

                          <h6 className="m-1">--- {experiment.id} ---</h6>
                        </CustomAccordionToggle>
                        {/* Experiment body */}
                        <Accordion.Collapse
                          eventKey={index}
                          className={`${darkMode && "darkmode-tertiary"}`}
                        >
                          <Card.Body className="p-1">
                            <Table
                              className="mb-1"
                              striped
                              bordered
                              variant={`${darkMode && "dark"}`}
                            >
                              <tbody>
                                {/* ID field */}
                                {/* <tr>
                                  <td className="fw-bold" width={"150px"}>
                                    <VscKey size={"2em"} /> Id:
                                  </td>
                                  <td>{experiment.id}</td>
                                </tr> */}
                                {/* Note field */}
                                <tr>
                                  <td className="fw-bold" width={"150px"}>
                                    <BiNotepad size={"2em"} /> Notes:
                                  </td>
                                  <td>{experiment.note}</td>
                                </tr>
                                {/* Updated at field */}
                                <tr>
                                  <td className="fw-bold" width={"150px"}>
                                    <MdOutlineUpdate size={"2em"} /> Updated at:
                                  </td>
                                  <td>
                                    {experiment.updated_at.slice(0, 10)} |{" "}
                                    {experiment.updated_at.slice(11, 19)}
                                  </td>
                                </tr>
                                {/* Created at field */}
                                <tr>
                                  <td className="fw-bold" width={"150px"}>
                                    <MdOutlineQueryBuilder size={"2em"} />{" "}
                                    Created at:
                                  </td>
                                  <td>
                                    {experiment.created_at.slice(0, 10)} |{" "}
                                    {experiment.created_at.slice(11, 19)}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            {/* Open experiment button */}
                            <div className="d-flex justify-content-end">
                              <Button
                                className="fw-bold"
                                onClick={() =>
                                  selectExperiment(
                                    experiment.id,
                                    experiment.title,
                                    experiment.note
                                  )
                                }
                                variant={`${darkMode ? "dark" : "light"}`}
                              >
                                <BiEdit size={"2em"} /> Open
                              </Button>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    );
                  }
                })}
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
