import React from "react";
// Variable handling
import { useState, useContext } from "react";
// Bootstrap components
import { Card, Row, Col, Container, Accordion } from "react-bootstrap";
// Custom accordion toggle
import CustomAccordionToggle from "../components/CustomAccordionToggle";
// Page component
import Info from "./Info";
// CSS
import "../assets/Editor.css";
// Reference to color theme
import { ThemeContext } from "../context/ThemeContext";
// Custom components
import PageGrid from "./PageGrid";
import ExperimentInfo from "./ExperimentInfo";

const NavigationGrid = ({
  selectedExperiments,
  selectedExperimentCodecies,
  averagedExperiment,
  averagedExperimentCodex,
  grayscalePoints,
  selectExperimentPage,
}) => {
  // Color theme
  const { darkMode } = useContext(ThemeContext);

  // Active keys for the accordion
  const [activeAccordItems, setActiveAccordItems] = useState(["A", "B", "C"]);

  return (
    <Container>
      {/* Navigation grids*/}
      <Row>
        <Col>
          <Accordion defaultActiveKey={["A", "B", "C"]} alwaysOpen>
            {/* Scribe Predictions */}
            <Card
              border={`${darkMode && "dark"}`}
              className={`mb-1 ${darkMode && "darkmode-secondary"}`}
            >
              {/* Header as a custom Accordion toggle */}
              <CustomAccordionToggle
                eventKey={"A"}
                activeItems={activeAccordItems}
                setActiveItems={setActiveAccordItems}
                canOpenMultiple={true}
              >
                <h5 className="m-1">
                  AI Prediction Scribes{" "}
                  <Info
                    text={
                      "The scribe predicted by the AI model for each individual page."
                    }
                  />
                </h5>
              </CustomAccordionToggle>
              {/* Body as a custom Accordion body */}
              <Accordion.Collapse eventKey={"A"} className="m-1">
                <div>
                  {/* Averaged experiment */}
                  {averagedExperimentCodex.id != "" && (
                    <div>
                      <Row className="mx-1">
                        <CustomAccordionToggle
                          eventKey={"averagedScribe"}
                          activeItems={activeAccordItems}
                          setActiveItems={setActiveAccordItems}
                          canOpenMultiple={true}
                        >
                          <div onClick={(e) => e.stopPropagation()}>
                            <ExperimentInfo
                              experiment={averagedExperiment}
                              grayscalePoints={grayscalePoints}
                            >
                              <Card
                                className={`m-1 py-1 px-2 fw-bold ${
                                  darkMode && "darkmode-secondary"
                                }`}
                              >
                                {averagedExperiment.title}
                              </Card>
                            </ExperimentInfo>
                          </div>
                        </CustomAccordionToggle>
                      </Row>
                      <Row>
                        <Accordion.Collapse
                          eventKey={"averagedScribe"}
                          className="m-1"
                        >
                          <PageGrid
                            selectedCodex={averagedExperimentCodex}
                            grayscalePoints={grayscalePoints}
                            selectExperimentPage={selectExperimentPage}
                            colorType={"predictionScribe"}
                          />
                        </Accordion.Collapse>
                      </Row>
                    </div>
                  )}

                  {/* Multiple experiments */}
                  {selectedExperimentCodecies.map((codex, codexIndex) => {
                    return (
                      <div key={selectedExperiments[codexIndex].id + "A"}>
                        <Row className="mx-1">
                          <CustomAccordionToggle
                            eventKey={
                              (selectedExperiments[codexIndex]
                                ? selectedExperiments[codexIndex].id
                                : "-") + "A"
                            }
                            activeItems={activeAccordItems}
                            setActiveItems={setActiveAccordItems}
                            canOpenMultiple={true}
                          >
                            <div onClick={(e) => e.stopPropagation()}>
                              <ExperimentInfo
                                experiment={selectedExperiments[codexIndex]}
                                grayscalePoints={grayscalePoints}
                              >
                                <Card
                                  className={`m-1 py-1 px-2 fw-bold ${
                                    darkMode && "darkmode-secondary"
                                  }`}
                                >
                                  {selectedExperiments[codexIndex] &&
                                    selectedExperiments[codexIndex].title}
                                </Card>
                              </ExperimentInfo>
                            </div>
                          </CustomAccordionToggle>
                        </Row>
                        <Row>
                          <Accordion.Collapse
                            eventKey={
                              (selectedExperiments[codexIndex]
                                ? selectedExperiments[codexIndex].id
                                : "-") + "A"
                            }
                            className="m-1"
                          >
                            <PageGrid
                              selectedCodex={codex}
                              grayscalePoints={grayscalePoints}
                              selectExperimentPage={selectExperimentPage}
                              colorType={"predictionScribe"}
                            />
                          </Accordion.Collapse>
                        </Row>
                      </div>
                    );
                  })}
                </div>
              </Accordion.Collapse>
            </Card>
            {/* Confidence Predictions */}
            <Card
              border={`${darkMode && "dark"}`}
              className={`mb-1 ${darkMode && "darkmode-secondary"}`}
            >
              {/* Header as a custom Accordion toggle */}
              <CustomAccordionToggle
                eventKey={"B"}
                activeItems={activeAccordItems}
                setActiveItems={setActiveAccordItems}
                canOpenMultiple={true}
              >
                <h5 className="m-1">
                  AI Prediction Confidence{" "}
                  <Info
                    text={
                      "The confidence of the AI model for each individual page."
                    }
                  />
                </h5>
              </CustomAccordionToggle>
              {/* Body as a custom Accordion body */}
              <Accordion.Collapse eventKey={"B"} className="m-1">
                <div>
                  {/* Averaged experiment */}
                  {averagedExperimentCodex.id != "" && (
                    <div>
                      <Row className="mx-1">
                        <CustomAccordionToggle
                          eventKey={"averagedConfidence"}
                          activeItems={activeAccordItems}
                          setActiveItems={setActiveAccordItems}
                          canOpenMultiple={true}
                        >
                          <div onClick={(e) => e.stopPropagation()}>
                            <ExperimentInfo
                              experiment={averagedExperiment}
                              grayscalePoints={grayscalePoints}
                            >
                              <Card
                                className={`m-1 py-1 px-2 fw-bold ${
                                  darkMode && "darkmode-secondary"
                                }`}
                              >
                                {averagedExperiment.title}
                              </Card>
                            </ExperimentInfo>
                          </div>
                        </CustomAccordionToggle>
                      </Row>
                      <Row>
                        <Accordion.Collapse
                          eventKey={"averagedConfidence"}
                          className="m-1"
                        >
                          <PageGrid
                            selectedCodex={averagedExperimentCodex}
                            grayscalePoints={grayscalePoints}
                            selectExperimentPage={selectExperimentPage}
                            colorType={"confidence"}
                          />
                        </Accordion.Collapse>
                      </Row>
                    </div>
                  )}

                  {/* Multiple experiments */}
                  {selectedExperimentCodecies.map((codex, codexIndex) => {
                    return (
                      <div key={selectedExperiments[codexIndex].id + "A"}>
                        <Row className="mx-1">
                          <CustomAccordionToggle
                            eventKey={
                              (selectedExperiments[codexIndex]
                                ? selectedExperiments[codexIndex].id
                                : "-") + "B"
                            }
                            activeItems={activeAccordItems}
                            setActiveItems={setActiveAccordItems}
                            canOpenMultiple={true}
                          >
                            <div onClick={(e) => e.stopPropagation()}>
                              <ExperimentInfo
                                experiment={selectedExperiments[codexIndex]}
                                grayscalePoints={grayscalePoints}
                              >
                                <Card
                                  className={`m-1 py-1 px-2 fw-bold ${
                                    darkMode && "darkmode-secondary"
                                  }`}
                                >
                                  {selectedExperiments[codexIndex] &&
                                    selectedExperiments[codexIndex].title}
                                </Card>
                              </ExperimentInfo>
                            </div>
                          </CustomAccordionToggle>
                        </Row>
                        <Row>
                          <Accordion.Collapse
                            eventKey={
                              (selectedExperiments[codexIndex]
                                ? selectedExperiments[codexIndex].id
                                : "-") + "B"
                            }
                            className="m-1"
                          >
                            <PageGrid
                              selectedCodex={codex}
                              grayscalePoints={grayscalePoints}
                              selectExperimentPage={selectExperimentPage}
                              colorType={"confidence"}
                            />
                          </Accordion.Collapse>
                        </Row>
                      </div>
                    );
                  })}
                </div>
              </Accordion.Collapse>
            </Card>
            {/* Croundthruth Scribes */}
            <Card
              border={`${darkMode && "dark"}`}
              className={`mb-1 ${darkMode && "darkmode-secondary"}`}
            >
              {/* Header as a custom Accordion toggle */}
              <CustomAccordionToggle
                eventKey={"C"}
                activeItems={activeAccordItems}
                setActiveItems={setActiveAccordItems}
                canOpenMultiple={true}
              >
                <h5 className="m-1">
                  Groundtruth Scribes{" "}
                  <Info
                    text={"The groundtruth-scribe for each individual page."}
                  />
                </h5>
              </CustomAccordionToggle>
              {/* Body as a custom Accordion body */}
              <Accordion.Collapse eventKey={"C"} className="m-1">
                <div>
                  {selectedExperimentCodecies[0] && (
                    <div>
                      <Row>
                        <PageGrid
                          selectedCodex={selectedExperimentCodecies[0]}
                          grayscalePoints={grayscalePoints}
                          selectExperimentPage={selectExperimentPage}
                          colorType={"groundtruthScribe"}
                        />
                      </Row>
                    </div>
                  )}
                </div>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default NavigationGrid;
