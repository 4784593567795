import React from "react";
// Variable handling
import { useState, useEffect, useContext } from "react";
// Page components
import Info from "./Info";
import PrismaZoom from "./PrismaZoom";
// Bootstrap components
import {
  Card,
  Container,
  Table,
  Row,
  Col,
  Overlay,
  Button,
  Nav,
} from "react-bootstrap";
// Coloring methods
import { scribeColor, confidenceColor } from "../utilities/autoCssStyling";
// CSS
import "../assets/Editor.css";
// Reference variables
import { createRef, useRef } from "react";
// Icons
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { TbRotateDot } from "react-icons/tb";
// Reference to color theme
import { ThemeContext } from "../context/ThemeContext";

const ImageDisplay = ({
  // Title of component
  title,
  // Tooltip of component
  tooltip,
  //
  selectedExperiments,
  // Selected experiment pages
  experimentPages,
  // selected reference pages
  referencePage,
  // Function to remove loading-effect
  loaded,
  // Values for confidence coloring
  grayscaleAnchors,
  // Value for zooming image
  scrollStep,
  // Bool for changing display orientation
  isHorizontal,
  // Function to switch display orientation
  switchView,
}) => {
  // Color theme
  const { darkMode } = useContext(ThemeContext);

  // Experiment prediction selection
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);

  // Reference for zoom component
  const prismazoom = createRef();
  // Reference for zoom value display
  const target = useRef(null);
  // Flag for zoom-lock
  const [canZoom, setCanZoom] = useState(false);

  // Zoom value
  const [zoom, setZoom] = useState(0);
  const getZoom = () => {
    setZoom(prismazoom.current.getZoom());
  };

  // Update display on zoom
  useEffect(() => {
    getZoom();
  }, [prismazoom]);

  return (
    <Card style={{ height: "100%" }} border={`${darkMode && "dark"}`}>
      {/* Header */}
      <Card.Header
        className={`p-1 d-flex justify-content-center ${
          darkMode && "darkmode-primary"
        }`}
      >
        <Container>
          {/* Title with tooltip*/}
          <Row className="mb-1">
            <Col className="d-flex justify-content-between">
              <Button
                onClick={() => switchView()}
                variant={`${darkMode ? "dark" : "light"}`}
              >
                <TbRotateDot size={"25px"} />
              </Button>
            </Col>
            <Col className="d-flex justify-content-center" md="auto">
              <h3 className="mx-1">
                {title} <Info text={tooltip} />
              </h3>
            </Col>
            <Col className="d-flex justify-content-start"></Col>
          </Row>
          {/* Datatable */}
          <Row>
            <Col>
              {experimentPages ? (
                <>
                  {experimentPages[selectedPageIndex].id != "" && (
                    <Nav
                      fill
                      variant="tabs"
                      onSelect={(key) => {
                        setSelectedPageIndex(key);
                      }}
                      defaultActiveKey=""
                    >
                      {experimentPages.map((page, index) => {
                        return (
                          <Nav.Item key={index}>
                            <Nav.Link
                              eventKey={index}
                              className={`${
                                darkMode && index != selectedPageIndex
                                  ? "darkmode-primary"
                                  : "darkmode-secondary"
                              } `}
                            >
                              <h5>
                                {selectedExperiments &&
                                  selectedExperiments[index].title}
                              </h5>
                            </Nav.Link>
                          </Nav.Item>
                        );
                      })}
                    </Nav>
                  )}
                  <Table
                    className="text-center mb-2 fw-bold"
                    bordered
                    variant={`${darkMode ? "dark" : "secondary"}`}
                  >
                    {/* Header */}
                    <thead>
                      <tr>
                        <th>Codex</th>
                        <th>Page</th>
                        <th>Groundtruth</th>

                        <th>Prediction</th>
                        <th>Probability</th>
                      </tr>
                    </thead>
                    {/* Body */}
                    <tbody>
                      <tr>
                        <td className="white-field">
                          {experimentPages[selectedPageIndex].codexName}
                        </td>
                        <td className="white-field">
                          {experimentPages[selectedPageIndex].name}
                        </td>
                        <td
                          style={scribeColor(
                            experimentPages[selectedPageIndex].groundtruthScribe
                          )}
                        >
                          {
                            experimentPages[selectedPageIndex].groundtruthScribe
                              .name
                          }
                        </td>

                        <td
                          style={scribeColor(
                            experimentPages[selectedPageIndex]
                              .experimentPrediction.predictionScribe
                          )}
                        >
                          {
                            experimentPages[selectedPageIndex]
                              .experimentPrediction.predictionScribe.name
                          }
                        </td>
                        <td
                          style={confidenceColor(
                            experimentPages[selectedPageIndex]
                              .experimentPrediction.probability,
                            grayscaleAnchors
                          )}
                        >
                          {
                            experimentPages[selectedPageIndex]
                              .experimentPrediction.probability
                          }
                          %
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              ) : (
                <Table
                  className="text-center mb-2 fw-bold"
                  bordered
                  variant={`${darkMode ? "dark" : "secondary"}`}
                >
                  {/* Header */}
                  <thead>
                    <tr>
                      <th>Codex</th>
                      <th>Page</th>
                      <th>Groundtruth</th>
                    </tr>
                  </thead>
                  {/* Body */}
                  <tbody>
                    <tr>
                      <td className="white-field">{referencePage.codexName}</td>
                      <td className="white-field">{referencePage.name}</td>
                      <td style={scribeColor(referencePage.groundtruthScribe)}>
                        {referencePage.groundtruthScribe.name}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        </Container>
      </Card.Header>
      {/* Image */}
      <Card.Body ref={target} className={`${darkMode && "darkmode-secondary"}`}>
        <Row>
          <Col className="overflow-hidden">
            <PrismaZoom
              className="d-flex justify-content-center"
              maxZoom={20}
              decelerationDuration={0}
              ref={prismazoom}
              onZoomChange={() => getZoom()}
              scrollVelocity={scrollStep}
              allowZoom={canZoom}
              allowWheel={false}
            >
              <img
                variant="bottom"
                style={{
                  height: isHorizontal ? "400px" : "800px",
                  objectFit: "contain",
                  cursor: canZoom && "zoom-in",
                }}
                src={
                  experimentPages
                    ? experimentPages[0].imgLink
                    : referencePage.imgLink
                }
                onLoad={() => loaded()}
              ></img>
            </PrismaZoom>
          </Col>
          <Col className="my-auto" md={"auto"}>
            <Button
              onClick={() => setCanZoom(!canZoom)}
              variant={`${darkMode ? "dark" : "light"}`}
              className={`p-1`}
              style={{
                width: "40px",
                height: "40px",
              }}
            >
              {/* Toggle zoom icons */}
              {canZoom ? (
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  height={"30px"}
                  viewBox="0 0 300 300"
                >
                  <path
                    d="M236.17,236.17l-49.75-49.75m0,0c12.99-12.99,21.03-30.95,21.03-50.77,0-39.66-32.15-71.81-71.81-71.81S63.83,95.98,63.83,135.64s32.15,71.81,71.81,71.81c19.83,0,37.78-8.04,50.77-21.03Z"
                    style={{
                      fill: "none",
                      stroke: darkMode ? "white" : "black",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: "15px",
                    }}
                  />
                </svg>
              ) : (
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  height={"30px"}
                  viewBox="0 0 300 300"
                >
                  <path
                    d="M298.2,150.09C298.2,68.43,231.99,2.23,150.34,2.23S2.48,68.43,2.48,150.09s66.21,147.86,147.86,147.86,147.86-66.21,147.86-147.86Zm-270.63,0c0-67.79,54.98-122.77,122.77-122.77,29.37,0,56.37,10.33,77.49,27.56L55.12,227.58c-17.23-21.12-27.56-48.12-27.56-77.49Zm45.28,95.22L245.55,72.59c17.23,21.12,27.56,48.12,27.56,77.49,0,67.79-54.98,122.77-122.77,122.77-29.37,0-56.37-10.33-77.49-27.56Z"
                    style={{
                      fill: darkMode ? "white" : "black",
                    }}
                  />
                  <path
                    d="M236.17,236.17l-49.75-49.75m0,0c12.99-12.99,21.03-30.95,21.03-50.77,0-39.66-32.15-71.81-71.81-71.81S63.83,95.98,63.83,135.64s32.15,71.81,71.81,71.81c19.83,0,37.78-8.04,50.77-21.03Z"
                    style={{
                      fill: "none",
                      stroke: darkMode ? "white" : "black",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: "15px",
                    }}
                  />
                </svg>
              )}
            </Button>
          </Col>
        </Row>
      </Card.Body>
      {/* Zoom value */}
      <Overlay target={target.current} show={zoom != 1} placement="top">
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              position: "absolute",
              backgroundColor: "gray",
              padding: "2px 10px",
              color: "white",
              borderRadius: 3,
              ...props.style,
            }}
          >
            {zoom * 100}%
          </div>
        )}
      </Overlay>
    </Card>
  );
};

export default ImageDisplay;
