// Variable handling
import React, { useState, useContext, useEffect } from "react";
// Page navigation
import { useHistory } from "react-router-dom";

// React bootstrap components
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";

// API calls
import { APILogin } from "../utilities/apiCalls";

// Background Img
import libraryImg from "../assets/library.jpg";

// Reference for context-API
import { ThemeContext } from "../context/ThemeContext";
import { ExperimentContext } from "../context/ExperimentContext";

// CSS
import "../assets/Editor.css";

// Notification component
import { Report } from "notiflix/build/notiflix-report-aio";

export default function Home({ error }) {
  let history = useHistory();

  // Credentials for form
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Color theme
  const { darkMode } = useContext(ThemeContext);
  // Update flag
  const { updateNav } = useContext(ExperimentContext);

  // Login function
  const login = async (e) => {
    e.preventDefault();

    try {
      const loginResponse = await APILogin(email, password);

      if (loginResponse[1] === 200) {
        // Toggling flag
        localStorage.setItem("userLoggedIn", true);
        // Setting timout date in localstorage
        localStorage.setItem(
          "logoutDate",
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            new Date().getHours() + 1,
            new Date().getMinutes(),
            new Date().getSeconds()
          )
        );
        // Setting user-details
        localStorage.setItem("userName", loginResponse[0].name);
        localStorage.setItem("userId", loginResponse[0].id);
        // Toggling timeout-warning flag
        localStorage.setItem("sessionWarning", false);
        // Updating navbar
        updateNav();

        // Switching page
        history.push("/");
      } else {
        Report.failure("Login Error!", loginResponse[0].message, "OK");
      }

      return loginResponse[0];
    } catch (error) {
      Report.failure("Server Error!", "Issue with database.", "OK");
      //console.log(error);
    }
  };

  // Turning off notification for initial login
  useEffect(() => {
    localStorage.setItem("sessionWarning", true);
  }, []);

  return (
    <Container
      fluid
      style={{
        backgroundImage: `url(${libraryImg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Row style={{ height: "100vh" }}>
        <Col className="d-flex justify-content-center">
          <Card
            className="m-4"
            style={{ width: "25rem", height: "fit-content" }}
            border={`${darkMode && "dark"}`}
          >
            {/* Card title */}
            <Card.Header
              className={`text-center ${darkMode && "darkmode-primary"}`}
              type="text"
            >
              <h1 className="stuff">Login</h1>
            </Card.Header>
            {/* Card body */}
            <Card.Body className={`${darkMode && "darkmode-secondary"}`}>
              <Form onSubmit={login}>
                {/* Email field */}
                <Form.Group className="mb-3">
                  <Form.Label>Email Address</Form.Label>

                  <Form.Control
                    type="email"
                    placeholer="Enter Email"
                    onChange={({ target: { value } }) => setEmail(value)}
                  ></Form.Control>
                </Form.Group>
                {/* Password field */}
                <Form.Group className="mb-3">
                  <Form.Label> Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={({ target: { value } }) => setPassword(value)}
                  />
                </Form.Group>
                {/* Login button */}
                <Button
                  variant={`${darkMode ? "dark" : "light"}`}
                  type="submit"
                >
                  Log in
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
