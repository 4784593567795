import React, { useEffect } from "react";
// Variable handling
import { useContext } from "react";
// Coloring methods
import {
  scribeColor,
  confidenceColor,
  addStyle,
  removeStyle,
} from "../utilities/autoCssStyling";
// CSS
import "../assets/Editor.css";
// Reference to color theme
import { ThemeContext } from "../context/ThemeContext";

const PageGrid = ({
  selectedCodex,
  grayscalePoints,
  selectExperimentPage,
  colorType,
}) => {
  // Color theme
  const { darkMode } = useContext(ThemeContext);

  const ColorPage = (page) => {
    switch (colorType) {
      case "confidence":
        return confidenceColor(
          page.experimentPrediction.probability,
          grayscalePoints
        );
      case "predictionScribe":
        return scribeColor(page.experimentPrediction.predictionScribe);
      case "groundtruthScribe":
        return scribeColor(page.groundtruthScribe);
    }
  };

  useEffect(() => {}, [grayscalePoints]);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {/* Individual pages */}
      {selectedCodex.pages.map((page, i) => {
        return (
          <a
            key={i}
            href="#image-viewer"
            className="mb-1 ms-1"
            style={ColorPage(page)}
          >
            <div
              className={`a${page.id}`}
              style={{
                width: "20px",
                height: "25px",
              }}
              onMouseEnter={() => addStyle(`.a${page.id}`, "highlight-red")}
              onMouseLeave={() => removeStyle(`.a${page.id}`, "highlight-red")}
              onClick={() => selectExperimentPage(page.id)}
            ></div>
          </a>
        );
      })}
    </div>
  );
};

export default PageGrid;
