import React from "react";
// Variable handling
import { useState, useContext } from "react";
// Bootstrap components
import {
  Card,
  Container,
  Col,
  Row,
  Dropdown,
  Form,
  Nav,
} from "react-bootstrap";
// Page components
import PageIcon from "./PageIcon";
import Info from "./Info";
// Reference to color theme
import { ThemeContext } from "../context/ThemeContext";
// CSS
import "../assets/Editor.css";

const ExperimentPageGallery = ({
  title,
  tooltip,
  selectedExperiments,
  selectedExperimentCodecies,
  grayscalePoints,
  selectExperimentCodex,
  selectExperimentPage,
}) => {
  // Color theme
  const { darkMode } = useContext(ThemeContext);

  // Search field
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={`${className} ${darkMode && "darkmode-secondary"}`}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className={`mx-3 my-2 ${darkMode && "darkmode-tertiary"}`}
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
            style={{ width: "80%" }}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value ||
                child.props.children.startsWith(value) ||
                child.props.children.toLowerCase().startsWith(value) ||
                child.props.children.toUpperCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <Container style={{ height: "100%" }} className="mb-1">
      <Row>
        {/* Page gallery */}
        <Col>
          <Nav
            fill
            variant="tabs"
            onSelect={(key) => selectExperimentCodex(key)}
            defaultActiveKey=""
          >
            {selectedExperiments[0] &&
              selectedExperiments[0].codecies.map((codex, index) => {
                return (
                  <Nav.Item key={codex.id}>
                    <Nav.Link
                      eventKey={codex.id}
                      className={`${
                        darkMode &&
                        codex.id !=
                          (selectedExperimentCodecies[0]
                            ? selectedExperimentCodecies[0].id
                            : "-")
                          ? "darkmode-primary"
                          : "darkmode-secondary"
                      } `}
                    >
                      <h5>{codex.name}</h5>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
          </Nav>
          <Card
            className={`overflow-auto page-list ${
              darkMode && "darkmode-secondary"
            }`}
            style={{
              height: "9rem",
            }}
            border={`${darkMode && "dark"}`}
          >
            {selectedExperimentCodecies[0] &&
              selectedExperimentCodecies[0].pages.map((page, index) => {
                return (
                  <PageIcon
                    key={index}
                    page={page}
                    selectPage={selectExperimentPage}
                    grayscalePoints={grayscalePoints}
                  />
                );
              })}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ExperimentPageGallery;
