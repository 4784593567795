// Sending credentials for accessing ScribeId web application
export const APILogin = async (email, password) => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/auth/login",
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        mode: "cors",
      },
    }
  );

  const data = await res.json();

  return [data, res.status];
};

// Requesting to revoke access early for ScribeId web application
export const APILogout = async () => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/auth/logout",
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );

  const response = await res.json();
};

// Access check for application (whether it timed out or not)
export const APITestConnection = async () => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/experiments",
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );

  return res.status;
};

// Returns overview data on all experiments in database
export const APIGetAllExperiments = async () => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/experiments",
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );

  const data = await res.json();
  const filteredData = data.filter((obj) => obj.title !== "");
  return filteredData;
};

// Returns complete data on specific experiment, selected with it's id
export const APIGetExperimentById = async (experiementId) => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/experiments/" +
      experiementId,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );

  const data = await res.json();

  return data;
};

// Returns complete data on an array of experimenta, selected with their shared parentid
export const APIGetExperimentsByParentId = async (parentId) => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/experiments/parent/" +
      parentId,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );

  const data = await res.json();

  return data;
};

// Returns data on an array containing all scribes in database
export const APIGetScribes = async () => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/scribes",
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );

  const data = await res.json();

  return data;
};

// Returns all pages, that have the specified scribe as their goundthruth
export const APIGetPagesByScribeId = async (scribeId) => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/scribes/" +
      scribeId +
      "/pages",
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );

  const data = await res.json();

  return data;
};

// Returns an array of percentage based feedbacks for a specified page up to a specified amount
export const APIGetPageCorrectionsByPageId = async (pageId, limit) => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/corrections/search?pageId=" +
      pageId +
      "&limit=" +
      limit,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );

  const data = await res.json();

  return data;
};

// Returns all percentage based feedbacks for every page
export const APIGetAllPageCorrections = async () => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/corrections",
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );
  const data = await res.json();

  return data;
};

// Sends a percentage based feedback for a page to the database
export const APIUploadPageCorrection = async (
  pageId,
  note,
  correctorId,
  experiementId,
  corrections
) => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/corrections",
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        pageId: pageId,
        note: note,
        correctorId: correctorId,
        experimentID: experiementId,
        corrections: corrections,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        mode: "cors",
      },
    }
  );

  const response = await res.json();

  return response;
};

// Returns an array of text based feedbacks for a specified page
export const APIGetPageNotesByPageId = async (pageId) => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/page-notes/" +
      pageId,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );

  const data = await res.json();

  return data;
};

// Returns all text based feedback for all pages
export const APIGetAllPageNotes = async () => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/page-notes",
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );
  const data = await res.json();

  return data;
};

// Sends a text based feedback for a page to the database
export const APIUploadPageNote = async (pageIds, note, correctorId) => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/page-notes",
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        pageIds: pageIds,
        note: note,
        correctorId: correctorId,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        mode: "cors",
      },
    }
  );

  const response = await res.json();

  return response;
};

// Returns all text based feedback for a specified scribe
export const APIGetCommentsByScribeId = async (scribeId) => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/scribes/" +
      scribeId,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  );

  const data = await res.json();

  return data;
};

// Sends a text based feedback for a scribe to the database
export const APIUploadComment = async (scribeId, comment) => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/scribes/" +
      scribeId +
      "/notes",
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        note: comment,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        mode: "cors",
      },
    }
  );

  const response = await res.json();

  return response;
};

// Sends a creation request for a new scribe to the database
export const APICreateScribe = async (scribeName) => {
  const res = await fetch(
    "https://scribe-backend.neuronalresearch.media.fhstp.ac.at/api/v1/scribes",
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        name: scribeName,
        codex: "",
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        mode: "cors",
      },
    }
  );

  const response = await res.json();

  return response;
};
