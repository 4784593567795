import React from "react";

import { useState, useContext, useEffect } from "react";

// Color scheme
import { ThemeContext } from "../context/ThemeContext";

// Custom components
import Info from "./Info";

// Bootstrap components
import { Container, Row, Col, Card } from "react-bootstrap";

// Slider component
import Slider from "@mui/material/Slider";

// Coloring methods
import { scribeColor, confidenceColor } from "../utilities/autoCssStyling";

const LegendDisplay = ({ scribes, grayscalePoints, changeGrayScalePoints }) => {
  // Color theme
  const { darkMode } = useContext(ThemeContext);

  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 20,
      label: "20%",
    },
    {
      value: 40,
      label: "40%",
    },
    {
      value: 60,
      label: "60%",
    },
    {
      value: 80,
      label: "80%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  // Arrays for the two legends
  const [confLegend, setConfLegend] = useState([]);

  // Update for grayscale slider
  const minDistance = 5;
  const updateThresholds = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        changeGrayScalePoints(clamped, clamped + minDistance);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        changeGrayScalePoints(clamped - minDistance, clamped);
      }
    } else {
      changeGrayScalePoints(newValue[0], newValue[1]);
    }

    updateLegend();
  };

  // Updating legend display with new values
  const updateLegend = () => {
    setConfLegend([
      {
        title: `< ${grayscalePoints[0]}%`,
        value: grayscalePoints[0] - 100,
      },
      {
        title: `${grayscalePoints[0]}% - ${grayscalePoints[1]}%`,
        value:
          grayscalePoints[0] + (grayscalePoints[1] - grayscalePoints[0]) / 2,
      },
      {
        title: `${grayscalePoints[1]}% < `,
        value: grayscalePoints[1] + 100,
      },
    ]);
  };

  useEffect(() => {
    updateLegend();
  }, []);

  return (
    <Container>
      {/* Legends */}
      <Row>
        {/* Scribes */}
        <Col>
          <Card border={`${darkMode && "dark"}`}>
            {/* Title */}
            <Card.Header
              className={`p-2 px-3 ${darkMode && "darkmode-primary"}`}
            >
              <h5 className="m-0">
                Scribe coloring{" "}
                <Info text={"Colors for the scribes in the experiment."} />
              </h5>
            </Card.Header>
            <Card.Body
              className={`p-2 d-flex flex-row flex-wrap ${
                darkMode && "darkmode-secondary"
              }`}
            >
              {/* List of scribes in corresponding colors */}
              {scribes.map((scribe, index) => {
                if (scribe.inExperiment)
                  return (
                    <Card
                      key={index}
                      className={`m-1 py-1 px-2 fw-bold`}
                      style={scribeColor(scribe, true, darkMode)}
                    >
                      {scribe.name}
                    </Card>
                  );
              })}
            </Card.Body>
          </Card>
        </Col>
        {/* Confidence */}
        <Col md={5} lg={4} xl={3}>
          <Card border={`${darkMode && "dark"}`}>
            {/* Title */}
            <Card.Header
              className={`p-2 px-3 ${darkMode && "darkmode-primary"}`}
            >
              <h5 className="m-0">
                AI Confidence coloring{" "}
                <Info text="Adjustable range for coloring the confidence of the AI model." />
              </h5>
            </Card.Header>
            <Card.Body className={`p-2 ${darkMode && "darkmode-secondary"}`}>
              <Container>
                {/* Display for values */}
                <Row>
                  <Col className="d-flex flex-row">
                    {confLegend.map((entry, index) => {
                      return (
                        <Card
                          key={index}
                          className="m-1 py-1 px-1 flex-fill text-center fw-bold"
                          style={confidenceColor(entry.value, grayscalePoints)}
                        >
                          {entry.title}
                        </Card>
                      );
                    })}
                  </Col>
                </Row>
                {/* Slider for confidence-colors */}
                <Row>
                  <Col>
                    <Slider
                      value={grayscalePoints}
                      onChange={updateThresholds}
                      min={0}
                      max={100}
                      step={5}
                      marks={marks}
                      disableSwap
                      track={false}
                      color="primary"
                    />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LegendDisplay;
