import React, { useEffect } from "react";
// Variable handling
import { useState } from "react";
import { useContext } from "react";
import { Page, Scribe } from "../utilities/customDataFormats";
// Coloring method
import { scribeColor } from "../utilities/autoCssStyling";
// Bootstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Dropdown,
  Form,
  Card,
  ProgressBar,
  Accordion,
  Nav,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
// Custom accordion toggle
import CustomAccordionToggle from "../components/CustomAccordionToggle";
// Page components
import Feedback from "./Feedback";
import Info from "./Info";
// Popup
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
// Reference to color theme
import { ThemeContext } from "../context/ThemeContext";
// CSS
import "../assets/Editor.css";
// Icons
import { MdOutlineQueryBuilder, MdOutlineQuickreply } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { FaPlus } from "react-icons/fa";
import { AiOutlineUserAdd } from "react-icons/ai";

const FeedbackForm = ({
  // Selected experiment pages
  experimentPages,
  // Selected reference page
  referencePage,
  // List of scribes
  scribes,
  // List of comments for selected scribe
  scribeComments,
  // Function to get comments for selected scribe (scribeId, setter for shown comments)
  getScribeComments,
  // Function to save correction to selected experiment page (pageId, list of corrections)
  savePageCorrection,
  // Function to save page-note to selected experiment page (experimentPageId, referencePageId, note)
  savePageNote,
  // Function to save comment to selected scribe (scribeId, comment)
  saveScribeComment,
  // Function to create new scribe (name of scribe)
  createScribe,
}) => {
  // Color theme
  const { darkMode } = useContext(ThemeContext);

  const [activeExperimentPage, setActiveExperimentPage] = useState(new Page());

  // Feedback display type: correction / note / scribe
  const [showType, setShowType] = useState("correction");
  // Navigation tab headers
  const [navItems, setNavItems] = useState([
    {
      title: "Corrections",
      eventkey: "correction",
      tooltip: "Confidence based feedback on the writer of the selected page.",
    },
    {
      title: "Page Notes",
      eventkey: "note",
      tooltip: "Text based feedback on the selected page.",
    },
    {
      title: "Scribe Notes",
      eventkey: "scribe",
      tooltip: "Text based feedback on a selected scribe.",
    },
  ]);
  // Scribe comment
  const [chosenScribe, setChosenScribe] = useState(new Scribe());
  const [comment, setComment] = useState("");

  // --- SELECTED SCRIBES ---
  // Variables
  const [usedScribes, setUsedScribes] = useState([]);

  // Methods
  const hideScribe = (id) => {
    setUsedScribes((current) => [...current, id]);
  };

  const restoreScribe = (id) => {
    setUsedScribes((current) =>
      current.filter((hand) => {
        return hand !== id;
      })
    );
  };

  // --- CORRECTIONS ---
  // Variables
  const [corrections, setCorrections] = useState([]);

  // Methods
  const addCorrection = (scribeId) => {
    hideScribe(scribeId);
    setCorrections((current) => [
      ...current,
      { scribeId: scribeId, confidence: 0 },
    ]);
  };

  const deleteCorrection = (scribeId) => {
    restoreScribe(scribeId);
    setCorrections((current) =>
      current.filter((correction) => {
        return correction.scribeId !== scribeId;
      })
    );
  };

  const updateCorrection = (scribeId, confidence) => {
    const changedCorrections = corrections.map((correction) => {
      if (correction.scribeId === scribeId) {
        return { ...correction, confidence: confidence / 100 };
      }
      return correction;
    });
    setCorrections(changedCorrections);
  };

  // --- PROGRESS BAR ---
  const colorProgress = (progress) => {
    if (progress < 100) return "warning";
    if (progress == 100) return "success";
    if (progress > 100) return "danger";
  };

  // --- SCRIBE NAMES DISPLAY ---
  const findScribe = (scribeId) => {
    return scribes.find((o) => o.id == scribeId)
      ? scribes.find((o) => o.id == scribeId)
      : new Scribe("", "UNKNOWN");
  };

  // --- PAGE NOTE ---
  // Variables
  const [noteField, setNoteField] = useState("");

  // Search field
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={`${className} ${darkMode && "darkmode-secondary"}`}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className={`mx-3 my-2 ${darkMode && "darkmode-tertiary"}`}
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
            style={{ width: "80%" }}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value ||
                child.props.children.startsWith(value) ||
                child.props.children.toLowerCase().startsWith(value) ||
                child.props.children.toUpperCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  // Active key for the accordion
  const [activeAccordItems, setActiveAccordItems] = useState([]);

  // Closing everything on page or feedbacktype change
  useEffect(() => {
    setActiveAccordItems([]);
    if (experimentPages) {
      setActiveExperimentPage(experimentPages[0]);
    }
  }, [experimentPages, showType]);

  return (
    <Card style={{ height: "100%" }} border={`${darkMode && "dark"}`}>
      {/* --- NAVIGATION --- */}
      <Card.Header className={`${darkMode && "darkmode-primary"}`}>
        <h2 className="mb-3 text-center">Feedbacks</h2>
        <Nav
          fill
          variant="tabs"
          onSelect={(key) => setShowType(key)}
          defaultActiveKey="correction"
        >
          {navItems.map((navItem, index) => {
            return (
              <Nav.Item key={index}>
                <Nav.Link
                  eventKey={navItem.eventkey}
                  className={`${
                    darkMode &&
                    navItem.eventkey != showType &&
                    "darkmode-primary"
                  } ${
                    darkMode &&
                    navItem.eventkey == showType &&
                    "darkmode-secondary"
                  }`}
                >
                  <h5>
                    {navItem.title} <Info text={navItem.tooltip} />
                  </h5>
                </Nav.Link>
              </Nav.Item>
            );
          })}
        </Nav>
      </Card.Header>
      {/* --- CORRECTIONS --- */}
      {showType == "correction" && (
        <Card.Body
          className={`${darkMode && "darkmode-secondary"}`}
          style={{ maxHeight: "1005px", height: "20rem" }}
        >
          {/* - Adding corrections - */}
          <Card style={{ height: "49%" }} border={`${darkMode && "dark"}`}>
            <Card.Header
              className={`p-2 px-3 ${darkMode && "darkmode-primary"}`}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h5 className="m-0">New: {activeExperimentPage.name}</h5>
              <div>
                {/* Dropdown for scribes */}
                <Dropdown style={{ display: "inline-block" }} drop="down end">
                  <Dropdown.Toggle
                    className={`p-1`}
                    variant={`${darkMode ? "dark" : "light"}`}
                    style={{
                      height: "35px",
                    }}
                    disabled={activeExperimentPage.id == ""}
                  >
                    <FaPlus size={"22px"} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className={`overflow-auto `}
                    style={{
                      height: "15rem",
                      borderColor: darkMode && "black",
                    }}
                    as={CustomMenu}
                  >
                    {scribes.map((scribe, index) => {
                      if (!usedScribes.includes(scribe.id)) {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={() => addCorrection(scribe.id)}
                            style={scribeColor(scribe, false)}
                            className="fw-bold"
                          >
                            {scribe.name}
                          </Dropdown.Item>
                        );
                      }
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                {/* QuickFeedback */}
                <Button
                  style={{
                    ...scribeColor(
                      activeExperimentPage.experimentPrediction
                        .predictionScribe,
                      true,
                      darkMode
                    ),
                    ...{
                      height: "35px",
                    },
                  }}
                  onClick={() => {
                    Confirm.show(
                      "Confirm QuickCheck",
                      `Are you sure you want to give 100% confident correction on ${activeExperimentPage.experimentPrediction.predictionScribe.name}?`,
                      "Submit",
                      "Cancel",
                      () => {
                        savePageCorrection(activeExperimentPage.id, [
                          {
                            scribeId:
                              activeExperimentPage.experimentPrediction
                                .predictionScribe.id,
                            confidence: 1,
                          },
                        ]);
                        setCorrections([]);
                        setUsedScribes([]);
                      },
                      () => {}
                    );
                  }}
                  disabled={activeExperimentPage.id == ""}
                  className="fw-bold px-1 py-0 ms-2"
                >
                  <MdOutlineQuickreply size={"22px"} />{" "}
                  {
                    activeExperimentPage.experimentPrediction.predictionScribe
                      .name
                  }
                </Button>
              </div>
            </Card.Header>
            <Card.Body
              className={`p-2 overflow-auto ${darkMode && "darkmode-tertiary"}`}
            >
              <Container fluid>
                {/* Selected corrections */}
                {corrections.map((correction, index) => {
                  return (
                    <Row key={index}>
                      <Col>
                        <Feedback
                          scribe={findScribe(correction.scribeId)}
                          deleteHandler={deleteCorrection}
                          updateHandler={updateCorrection}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </Container>
            </Card.Body>
            <Card.Footer className={`${darkMode && "darkmode-primary"}`}>
              {/* Percentage feedback */}
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={
                  <Tooltip>
                    The confidence of the given corrections have to equal 100%
                  </Tooltip>
                }
              >
                <ProgressBar
                  as="Button"
                  now={
                    corrections.reduce(
                      (prev, curr) => prev + new Number(curr.confidence),
                      0
                    ) * 100
                  }
                  label={`${Math.round(
                    corrections.reduce(
                      (prev, curr) => prev + new Number(curr.confidence),
                      0
                    ) * 100
                  )}%`}
                  striped
                  variant={colorProgress(
                    corrections.reduce(
                      (prev, curr) => prev + new Number(curr.confidence),
                      0
                    ) * 100
                  )}
                  className="mb-2"
                />
              </OverlayTrigger>

              {/* Save button */}
              <OverlayTrigger
                trigger={
                  (corrections.reduce(
                    (prev, curr) => prev + new Number(curr.confidence),
                    0
                  ) != 1 ||
                    activeExperimentPage.id == "") && ["hover", "focus"]
                }
                placement="top"
                overlay={
                  <Tooltip>
                    You have to select a page from a codex featured in the
                    experiment first.
                  </Tooltip>
                }
              >
                <div>
                  <Button
                    className="w-100"
                    variant="success"
                    disabled={
                      corrections.reduce(
                        (prev, curr) => prev + new Number(curr.confidence),
                        0
                      ) != 1 || activeExperimentPage.id == ""
                    }
                    onClick={() => {
                      Confirm.show(
                        "Confirm Upload",
                        "Are you sure you want to submit this correction?",
                        "Submit",
                        "Cancel",
                        () => {
                          savePageCorrection(
                            activeExperimentPage.id,
                            corrections
                          );
                          setCorrections([]);
                          setUsedScribes([]);
                        },
                        () => {}
                      );
                    }}
                  >
                    SAVE CORRECTION
                  </Button>
                </div>
              </OverlayTrigger>
            </Card.Footer>
          </Card>
          {/* - Past corrections -*/}
          <Card
            style={{ height: "49%" }}
            border={`${darkMode && "dark"}`}
            className="mt-3"
          >
            <Card.Header
              className={`p-2 px-3 ${darkMode && "darkmode-primary"}`}
            >
              <h5 className="m-0">History: {activeExperimentPage.name}</h5>
            </Card.Header>
            <Card.Body
              className={`p-2 overflow-auto ${darkMode && "darkmode-tertiary"}`}
            >
              {/* Given feedbacks */}
              <Accordion alwaysOpen>
                {activeExperimentPage.corrections.map((correction, index) => {
                  // Corrections
                  return (
                    <Card
                      key={index}
                      className="mb-2"
                      border={`${darkMode && "dark"}`}
                    >
                      {/* Header */}
                      <CustomAccordionToggle
                        eventKey={index}
                        activeItems={activeAccordItems}
                        setActiveItems={setActiveAccordItems}
                        canOpenMultiple={true}
                      >
                        <div className="p-1 px-2 fw-bold">
                          <div>
                            <MdOutlineQueryBuilder size={"25px"} />{" "}
                            {correction.date} | {correction.time}
                          </div>
                          <div>
                            <CgProfile size={"25px"} />{" "}
                            {correction.corrector.name}
                          </div>
                        </div>
                      </CustomAccordionToggle>
                      {/* Body */}
                      <Accordion.Collapse eventKey={index}>
                        <Card.Body
                          className={`p-1 overflow-auto ${
                            darkMode && "darkmode-secondary"
                          }`}
                          style={{ maxHeight: "150px" }}
                        >
                          <Table
                            variant={`${darkMode ? "dark" : "secondary"}`}
                            className="text-center mb-0 fw-bold"
                            bordered
                            size="sm"
                          >
                            <thead>
                              <tr>
                                <th>Scribe</th>
                                <th>Confidence</th>
                              </tr>
                            </thead>
                            <tbody>
                              {correction.corrections.map(
                                (individualCorrection, i) => {
                                  return (
                                    <tr key={i}>
                                      <td
                                        style={scribeColor(
                                          individualCorrection.scribe
                                        )}
                                      >
                                        {individualCorrection.scribe.name}
                                      </td>
                                      <td
                                        style={{
                                          backgroundColor: "white",
                                          color: "black",
                                        }}
                                      >
                                        {individualCorrection.confidence * 100}%
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion>
            </Card.Body>
          </Card>
        </Card.Body>
      )}
      {/* --- NOTES --- */}
      {showType == "note" && (
        <Card.Body
          className={`${darkMode && "darkmode-secondary"}`}
          style={{ maxHeight: "1005px" }}
        >
          {/* - Adding notes - */}
          <Card style={{ height: "49%" }} border={`${darkMode && "dark"}`}>
            <Card.Header
              className={`p-2 px-3 ${darkMode && "darkmode-primary"}`}
            >
              <h5 className="m-0">New: {activeExperimentPage.name}</h5>
            </Card.Header>
            <Card.Body
              className={`p-2 overflow-auto ${darkMode && "darkmode-tertiary"}`}
            >
              <Form.Control
                as="textarea"
                rows={10}
                value={noteField}
                onChange={(e) => setNoteField(e.target.value)}
                style={{ height: "100%", resize: "none" }}
                className={`${darkMode && "darkmode-secondary"}`}
              />
            </Card.Body>
            <Card.Footer className={`${darkMode && "darkmode-primary"}`}>
              {/* Save button */}
              <OverlayTrigger
                trigger={
                  (noteField == "" || activeExperimentPage.id == "") && [
                    "hover",
                    "focus",
                  ]
                }
                placement="top"
                overlay={
                  <Tooltip>
                    You have to select a page from a codex featured in the
                    experiment first.
                  </Tooltip>
                }
              >
                <div>
                  <Button
                    className="w-100"
                    variant="success"
                    disabled={noteField == "" || activeExperimentPage.id == ""}
                    onClick={() => {
                      Confirm.show(
                        "Confirm Upload",
                        "Are you sure you want to submit this note?",
                        "Submit",
                        "Cancel",
                        () => {
                          savePageNote(
                            activeExperimentPage.id,
                            referencePage.id,
                            noteField
                          );
                          setNoteField("");
                        },
                        () => {}
                      );
                    }}
                  >
                    SAVE NOTE
                  </Button>
                </div>
              </OverlayTrigger>
            </Card.Footer>
          </Card>

          {/* - Past Notes -*/}
          <Card
            style={{ height: "49%" }}
            border={`${darkMode && "dark"}`}
            className="mt-3"
          >
            <Card.Header
              className={`p-2 px-3 ${darkMode && "darkmode-primary"}`}
            >
              <h5 className="m-0">History: {activeExperimentPage.name}</h5>
            </Card.Header>
            <Card.Body
              className={`p-2 overflow-auto ${darkMode && "darkmode-tertiary"}`}
            >
              <Accordion alwaysOpen>
                {activeExperimentPage.notes.map((note, index) => {
                  // Notes
                  return (
                    <Card
                      key={index}
                      className="mb-2"
                      border={`${darkMode && "dark"}`}
                    >
                      <CustomAccordionToggle
                        eventKey={index}
                        activeItems={activeAccordItems}
                        setActiveItems={setActiveAccordItems}
                        canOpenMultiple={true}
                      >
                        <div className="p-1 px-2 fw-bold">
                          <div>
                            <MdOutlineQueryBuilder size={"25px"} /> {note.date}
                            {" | "}
                            {note.time}
                          </div>
                          <div>
                            <CgProfile size={"25px"} /> {note.corrector.name}
                          </div>
                        </div>
                      </CustomAccordionToggle>

                      <Accordion.Collapse eventKey={index}>
                        <Card.Body
                          className={`overflow-auto p-2 ${
                            darkMode && "darkmode-secondary"
                          }`}
                          style={{ maxHeight: "150px" }}
                        >
                          {note.message}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion>
            </Card.Body>
          </Card>
        </Card.Body>
      )}
      {/* --- COMMENTS --- */}
      {showType == "scribe" && (
        <Card.Body
          className={`${darkMode && "darkmode-secondary"}`}
          style={{ maxHeight: "1005px" }}
        >
          <Row>
            <Col>
              {/* - Scribe Picker - */}
              <Dropdown>
                <Dropdown.Toggle
                  className="w-100 fw-bold"
                  style={scribeColor(chosenScribe, true, darkMode)}
                >
                  {chosenScribe.name}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="overflow-auto"
                  style={{
                    height: "15rem",
                    borderColor: darkMode && "black",
                  }}
                  as={CustomMenu}
                >
                  {scribes.map((scribe, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setChosenScribe(scribe);
                          getScribeComments(scribe.id);
                        }}
                        style={scribeColor(scribe, false)}
                        className="fw-bold"
                      >
                        {scribe.name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={2} lg={2} xl={3} xxl={3}>
              {/* New Scribe creation */}
              <Button
                className={`w-100`}
                variant={`${darkMode ? "dark" : "light"}`}
                onClick={() => {
                  Confirm.prompt(
                    "Create new Scribe",
                    `Give a name to the new scribe`,
                    "",
                    "Create",
                    "Cancel",
                    (name) => {
                      createScribe(name);
                    },
                    () => {}
                  );
                }}
              >
                <AiOutlineUserAdd size={"22px"} />
              </Button>
            </Col>
          </Row>
          {/* - New Comment - */}
          <Card
            style={{ height: "49%" }}
            className="mt-3"
            border={`${darkMode && "dark"}`}
          >
            <Card.Header
              className={`p-2 px-3 ${darkMode && "darkmode-primary"}`}
            >
              <h5 className="m-0">New: {chosenScribe.name}</h5>
            </Card.Header>
            <Card.Body
              className={`p-2 overflow-auto ${darkMode && "darkmode-tertiary"}`}
            >
              <Form.Control
                as="textarea"
                rows={10}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                style={{ height: "100%", resize: "none" }}
                className={`${darkMode && "darkmode-secondary"}`}
              />
            </Card.Body>
            <Card.Footer className={`${darkMode && "darkmode-primary"}`}>
              {/* Save button */}
              <OverlayTrigger
                trigger={
                  (comment == "" || chosenScribe.id == "") && ["hover", "focus"]
                }
                placement="top"
                overlay={<Tooltip>You have to select a scribe first.</Tooltip>}
              >
                <div>
                  <Button
                    className="w-100"
                    variant="success"
                    disabled={comment == "" || chosenScribe.id == ""}
                    onClick={() => {
                      Confirm.show(
                        "Confirm Upload",
                        "Are you sure you want to submit this comment?",
                        "Submit",
                        "Cancel",
                        () => {
                          saveScribeComment(chosenScribe.id, comment);
                          setComment("");
                          getScribeComments(chosenScribe.id);
                        },
                        () => {}
                      );
                    }}
                  >
                    POST COMMENT
                  </Button>
                </div>
              </OverlayTrigger>
            </Card.Footer>
          </Card>

          {/* - Past comments -*/}
          <Card
            style={{ height: "43%" }}
            className="mt-3"
            border={`${darkMode && "dark"}`}
          >
            <Card.Header
              className={`p-2 px-3 ${darkMode && "darkmode-primary"}`}
            >
              <h5 className="m-0">History: {chosenScribe.name}</h5>
            </Card.Header>
            <Card.Body
              className={`p-2 overflow-auto ${darkMode && "darkmode-tertiary"}`}
            >
              <Accordion alwaysOpen>
                {scribeComments.map((comment, index) => {
                  // Notes
                  return (
                    <Card
                      key={index}
                      className="mb-2"
                      border={`${darkMode && "dark"}`}
                    >
                      <CustomAccordionToggle
                        eventKey={index}
                        activeItems={activeAccordItems}
                        setActiveItems={setActiveAccordItems}
                        canOpenMultiple={true}
                      >
                        <div className="p-1 px-2 fw-bold">
                          <div>
                            <MdOutlineQueryBuilder size={"25px"} />{" "}
                            {comment.date} | {comment.time}
                          </div>
                          <div>
                            <CgProfile size={"25px"} /> {comment.corrector.name}
                          </div>
                        </div>
                      </CustomAccordionToggle>

                      <Accordion.Collapse eventKey={index}>
                        <Card.Body
                          className={`p-2 overflow-auto ${
                            darkMode && "darkmode-secondary"
                          }`}
                          style={{ maxHeight: "150px" }}
                        >
                          {comment.message}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion>
            </Card.Body>
          </Card>
        </Card.Body>
      )}
    </Card>
  );
};

export default FeedbackForm;
