// Variable handling
import React, { useContext } from "react";
// React bootstrap components
import { Card } from "react-bootstrap";
// Custom accordion component
import { useAccordionButton } from "react-bootstrap/AccordionButton";
// Icons
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
// Reference to color theme
import { ThemeContext } from "../context/ThemeContext";

function CustomAccordionToggle({
  // Components given to be displayed in the Accordion
  children,
  // ID of toggle
  eventKey,
  // List of currently active toggles
  activeItems,
  // Setter for the active toggles array
  setActiveItems,
  // Bool to determine, whether multiple toggles can be open at once
  canOpenMultiple,
}) {
  // Color theme
  const { darkMode } = useContext(ThemeContext);

  // Functionality for single or multiple openable item
  const customOnClick = useAccordionButton(eventKey, () => {
    // Multiple
    if (canOpenMultiple) {
      // Add myself
      if (!activeItems.includes(eventKey))
        setActiveItems((current) => [...current, eventKey]);
      // Remove myself
      else
        setActiveItems((current) =>
          current.filter((itemKey) => {
            return itemKey != eventKey;
          })
        );
    }
    // Single
    else {
      if (!activeItems.includes(eventKey)) setActiveItems([eventKey]);
      else setActiveItems([]);
    }
  });

  return (
    <Card.Header
      className={`px-2 py-1 ${
        darkMode && "darkmode-primary"
      } d-flex justify-content-between`}
      onClick={(e) => customOnClick(e)}
      style={{ cursor: "pointer" }}
    >
      {/* Returning children */}
      <div>{children}</div>
      {/* Arrow for open / close */}
      {!activeItems.includes(eventKey) ? (
        <IoIosArrowDown size={"30px"} className="my-auto" />
      ) : (
        <IoIosArrowUp size={"30px"} className="my-auto" />
      )}
    </Card.Header>
  );
}

export default CustomAccordionToggle;
