// Coloring methods
import {
  scribeColor,
  confidenceColor,
  addStyle,
  removeStyle,
  generatePattern,
} from "../utilities/autoCssStyling";
// Variable handling
import { useContext, useRef, useState } from "react";
// Bootstrap components
import { Card, OverlayTrigger, Popover, Table } from "react-bootstrap";
// CSS styling
import "../assets/Editor.css";
// Reference Icon
import { VscReferences } from "react-icons/vsc";
// CSS
import "../assets/Editor.css";
// Reference to color theme
import { ThemeContext } from "../context/ThemeContext";
// Icons
import { MdNoteAlt } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
// Placeholder image
import placeholderImg from "../assets/img-no-preview.png";

const PageIcon = ({
  // Specific page
  page,
  // Selecting specific page
  selectPage,
}) => {
  // Color theme
  const { darkMode } = useContext(ThemeContext);

  // Reference for icon display
  const target = useRef(null);

  // --- POPOVER ---
  const popoverImg = (
    <Popover>
      <Popover.Header
        as="h1"
        className={`p-1 text-center d-flex justify-content-center ${
          darkMode && "darkmode-primary"
        }`}
      >
        <Table
          className="mb-0"
          bordered
          variant={`${darkMode ? "dark" : "secondary"}`}
        >
          {/* Header */}
          <thead>
            <tr>
              <th>Scribe</th>

              <th>Codex</th>
            </tr>
          </thead>
          <tbody>
            {/* Info */}
            <tr className="fw-bold">
              <td style={scribeColor(page.groundtruthScribe)}>
                {page.groundtruthScribe.name}
              </td>

              <td
                style={{
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                {page.codexName}
              </td>
            </tr>
          </tbody>
        </Table>
      </Popover.Header>
      {/* Page preview */}
      <Popover.Body
        className={`p-1 d-flex justify-content-center ${
          darkMode && "darkmode-secondary"
        }`}
      >
        <img
          src={page.prevLink}
          alt="Thumbnail Image"
          width="200"
          height="280"
          onError={(e) => {
            e.currentTarget.src = placeholderImg;
          }}
        ></img>
      </Popover.Body>
    </Popover>
  );

  // Generating patterns for SVGs
  // const [predictionData, setPredictionData] = useState(
  //   page.experiment
  //     ? generatePattern(page.experiment.predScribeId, scribes)
  //     : ""
  // );
  // const [groundTData, setGroundTData] = useState(
  //   page.experiment ? generatePattern(page.gTScribeId, scribes) : ""
  // );

  return (
    <div style={{ height: "100%" }}>
      <a href="#image-viewer" onClick={() => selectPage(page.id)}>
        {/* Preview popup */}
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="top"
          overlay={popoverImg}
        >
          <Card
            className="mx-1 mt-1"
            bg={"dark"}
            style={{ height: "1rem" }}
            border={`${darkMode && "dark"}`}
          ></Card>
        </OverlayTrigger>
        {/* Card properties */}
        <Card
          className={`m-1 page-icon a${page.id}`}
          ref={target}
          onMouseEnter={() => addStyle(`.a${page.id}`, "highlight-red")}
          onMouseLeave={() => removeStyle(`.a${page.id}`, "highlight-red")}
          style={scribeColor(page.groundtruthScribe)}
        >
          {/* Types of cards */}
          {page.experimentPrediction ? (
            // Groundtruth and prediction scribe colors in an SVG
            <div>
              {/* Icons for past feedbacks */}
              <div
                style={{
                  height: "17px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {page.corrections.length != 0 ? (
                  <FaCheckCircle style={{ color: "red" }} />
                ) : (
                  <div></div>
                )}
                {page.notes.length != 0 ? (
                  <MdNoteAlt style={{ color: "red" }} />
                ) : (
                  <div></div>
                )}
              </div>
              <div className="p-2">
                <VscReferences size={"30px"} />
              </div>
              {/* SVG for groundthruth and prediction */}
              {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 100 100"
                >
                  <defs>
                    {predictionData.pattern}
                    {groundTData.pattern}
                    <pattern id="testPattern" width="100%" height="100%">
                      <rect
                        x="0"
                        y="0"
                        width="100"
                        height="100"
                        fill="purple"
                      />
                      <image
                        href={
                          process.env.PUBLIC_URL + "/diagonal-background.png"
                        }
                        width="200"
                      ></image>
                    </pattern>
                  </defs>
                  <path
                    id="upperTriangle"
                    fill={`url(#${predictionData.id})`}
                    stroke="#000"
                    strokeMiterlimit="10"
                    strokeWidth="10"
                    d="M100 100L100 0 0 0 100 100z"
                  ></path>
                  <path
                    id="lowerTriangle"
                    fill={`url(#${groundTData.id})`}
                    stroke="#000"
                    strokeMiterlimit="10"
                    strokeWidth="10"
                    d="M0 0L0 100 100 100 0 0z"
                  ></path>
                </svg> */}
            </div>
          ) : (
            // Reference icon
            <div className="p-2">
              <VscReferences size={"30px"} />
            </div>
          )}
          {/* Page number with selected coloring */}
          <div>
            <Card.Title className="text-center" style={{ fontSize: "18px" }}>
              {page.name}
            </Card.Title>
          </div>
        </Card>
      </a>
    </div>
  );
};

export default PageIcon;
