import React, { useEffect } from "react";
// Variable handling
import { useState, useContext } from "react";
// Bootstrap components
import {
  Card,
  Container,
  Col,
  Row,
  Dropdown,
  Form,
  Tooltip,
  OverlayTrigger,
  Nav,
} from "react-bootstrap";
// Coloring function
import { scribeColor } from "../utilities/autoCssStyling";
// Page components
import PageIcon from "./PageIcon";
import Info from "./Info";
// Reference to color theme
import { ThemeContext } from "../context/ThemeContext";
// CSS
import "../assets/Editor.css";
import { Codex } from "../utilities/customDataFormats";

const ReferencePageGallery = ({
  title,
  tooltip,
  referenceScribePages,
  scribes,
  selectReferenceScribe,
  selectReferencePage,
}) => {
  // Color theme
  const { darkMode } = useContext(ThemeContext);

  // Id of selected codex
  const [selectedCodexId, setSelectedCodexId] = useState("");
  const [selectedCodex, setSelectedCodex] = useState(new Codex());

  // Search field
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={`${className} ${darkMode && "darkmode-secondary"}`}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className={`mx-3 my-2 ${darkMode && "darkmode-tertiary"}`}
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
            style={{ width: "80%" }}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value ||
                child.props.children.startsWith(value) ||
                child.props.children.toLowerCase().startsWith(value) ||
                child.props.children.toUpperCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  useEffect(() => {
    const searchResult = referenceScribePages.codecies.find(
      (codex) => codex.id == selectedCodexId
    );

    if (searchResult) setSelectedCodex(searchResult);
  }, [selectedCodexId]);

  useEffect(() => {
    setSelectedCodexId("");
    setSelectedCodex(new Codex());
  }, [referenceScribePages]);

  return (
    <Container style={{ height: "100%" }} className="mb-1">
      <Row>
        {/* Group picker */}
        <Col md={4} lg={3} xl={2}>
          <Card
            className={`${darkMode && "darkmode-secondary"}`}
            style={{ height: "100%" }}
            border={`${darkMode && "dark"}`}
          >
            {/* Header */}
            <Card.Header
              className={`p-2 text-center ${darkMode && "darkmode-primary"}`}
            >
              <h5 className="m-0">
                {title} <Info text={tooltip} />
              </h5>
            </Card.Header>
            {/* Dropdown */}
            <Dropdown className="my-auto mx-2" drop="up">
              {/* Dropdown button with coloring for scribes */}
              <Dropdown.Toggle
                className={`w-100 fw-bold `}
                style={scribeColor(referenceScribePages.scribe, true, darkMode)}
              >
                {referenceScribePages.scribe.name}
              </Dropdown.Toggle>
              {/* Dropdown menu with filter */}
              <Dropdown.Menu
                className="overflow-auto"
                style={{
                  height: "15rem",
                  borderColor: darkMode && "black",
                }}
                as={CustomMenu}
              >
                {scribes.map((scribe, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={() => selectReferenceScribe(scribe.id)}
                      className={`fw-bold`}
                      style={scribeColor(scribe)}
                    >
                      {scribe.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Card>
        </Col>
        {/* Page gallery */}
        <Col>
          <Nav
            fill
            variant="tabs"
            onSelect={(key) => setSelectedCodexId(key)}
            defaultActiveKey=""
          >
            {referenceScribePages.codecies.map((codex, index) => {
              return (
                <Nav.Item key={codex.id}>
                  <Nav.Link
                    eventKey={codex.id}
                    className={`${
                      darkMode && codex.id != selectedCodexId
                        ? "darkmode-primary"
                        : "darkmode-secondary"
                    }`}
                  >
                    <h5>{codex.name}</h5>
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
          <Card
            className={`overflow-auto page-list ${
              darkMode && "darkmode-secondary"
            }`}
            style={{
              height: "9rem",
            }}
            border={`${darkMode && "dark"}`}
          >
            {selectedCodex.pages.map((page, i) => {
              return (
                <PageIcon
                  key={i}
                  page={page}
                  selectPage={selectReferencePage}
                />
              );
            })}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ReferencePageGallery;
