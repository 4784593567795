// Returning CSS classes for coloring the confidence of the AI model
export const confidenceColor = (
  // Confidence of the AI (0-100 float)
  percentage,
  // Thresholds: adjustable points for determining the color (2 element float array)
  thresholds = [70, 90]
) => {
  // If a non-valid percentage is given
  if (isNaN(percentage))
    return {
      backgroundColor: "white",
      color: "black",
    };
  // Retuning colors based on the given thresholds
  if (percentage > thresholds[1])
    return {
      backgroundColor: `hsl(0, 0%, 25%)`,
      color: "white",
    };
  if (percentage >= thresholds[0])
    return {
      backgroundColor: `hsl(0, 0%, 60%)`,
      color: "white",
    };
  else
    return {
      backgroundColor: `hsl(0, 0%, 90%)`,
      color: "black",
    };
};

// Returning CSS classes for coloring the fields of the scribes
export const scribeColor = (
  // Selected scribe
  scribe,
  // Toggle for still overwriting the style if no match found (bool)
  overwriteStyle = true,
  // Variable for coloring the border, based on the color-theme (null/bool)
  borderStyleDarkMode = null
) => {
  // HEX to RGB
  const hexToRgb = (hex) => {
    var bigint = parseInt(hex, 16);
    var red = (bigint >> 16) & 255;
    var green = (bigint >> 8) & 255;
    var blue = bigint & 255;

    return { r: red, g: green, b: blue };
  };

  // Method for determining basic background color
  const borderTypeBasic = (type) => {
    // No styling needed
    if (type == null) return "";
    // Darkmode
    if (type) return "white";
    // Lightmode
    else return "black";
  };

  // Converting HEX to RGB
  var colorRgb = hexToRgb(scribe.backgroundColour);

  // Calculating luminace for textcolor
  var luminace =
    (0.299 * colorRgb.r + 0.587 * colorRgb.g + 0.114 * colorRgb.b) / 255;

  // Method for returning textcolor based on luminace and background-type
  const textColor = (luminace, background) => {
    if (background == "") {
      return luminace > 0.5 ? "black" : "white";
    } else {
      return luminace > 0.65 ? "black" : "white";
    }
  };

  // Method for returning url for background images
  const backgroundType = (type) => {
    if (type == "striped")
      return process.env.PUBLIC_URL + "/diagonal-background.png";
    if (type == "dotted")
      return process.env.PUBLIC_URL + "/dots-background.png";
    else return "";
  };

  // Method for returning custom background color
  const borderTypeCustom = (type) => {
    // No styling needed
    if (type == null) return "";
    // Darkmode
    if (type) return `rgb(${colorRgb.r}, ${colorRgb.g}, ${colorRgb.b})`;
    // Lightmode
    else return luminace > 0.5 ? "black" : "white";
  };

  // Returning custom CSS styling
  return {
    backgroundColor: `rgb(${colorRgb.r}, ${colorRgb.g}, ${colorRgb.b})`,
    color: textColor(luminace, scribe.backgroundType),
    borderColor: borderTypeCustom(borderStyleDarkMode),
    backgroundImage: `url(${backgroundType(scribe.backgroundType)})`,
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
    backgroundSize: "100px",
  };
};

// Adding CSS class to target component
export const addStyle = (target, classname) => {
  const amount = document.querySelectorAll(target).length;
  for (let x = 0; x < amount; x++) {
    document.querySelectorAll(target)[x].classList.add(classname);
  }
};

// Removing CSS class to target component
export const removeStyle = (target, classname) => {
  const amount = document.querySelectorAll(target).length;
  for (let x = 0; x < amount; x++) {
    document.querySelectorAll(target)[x].classList.remove(classname);
  }
};

// Pattern generator for SVG in PageIcon
export const generatePattern = (scribe) => {
  const backgroundType = (type) => {
    if (type == "") return "";
    if (type == "striped")
      return process.env.PUBLIC_URL + "/diagonal-background.png";
    if (type == "dotted")
      return process.env.PUBLIC_URL + "/dots-background.png";
  };

  return {
    id: "a" + scribe.id,
    pattern: (
      <pattern id={"a" + scribe.id} width="100%" height="100%">
        <rect
          x="0"
          y="0"
          width="100"
          height="100"
          fill={scribeColor(scribe).backgroundColor}
        />
        <image href={`${backgroundType(scribe.backgroundType)}`} width="200" />
      </pattern>
    ),
  };
};
