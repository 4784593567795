import React from "react";

import { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../context/ThemeContext";

// Popups
import { Report } from "notiflix/build/notiflix-report-aio";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

// Custom data formats
import {
  Scribe,
  Codex,
  Experiment,
  Page,
  ScribePageCollection,
  PageExperimentPrediction,
  PageCorrection,
  PageNote,
  Corrector,
  IndividualCorrection,
  PredictionScribe,
  ScribeComment,
  AveragedPagePrediction,
} from "../utilities/customDataFormats";

// API Calls
import {
  APIGetExperimentById,
  APIGetExperimentsByParentId,
  APIGetScribes,
  APIGetPagesByScribeId,
  APIGetAllPageCorrections,
  APIUploadPageCorrection,
  APIGetAllPageNotes,
  APIUploadPageNote,
  APIGetCommentsByScribeId,
  APIUploadComment,
  APICreateScribe,
} from "../utilities/apiCalls";

// Bootstrap components
import { Container, Row, Col } from "react-bootstrap";

// Custom components
import LegendDisplay from "../components/LegendDisplay";
import NavigationGrid from "../components/NavigationGrid";
import ImageDisplay from "../components/ImageDisplay";
import ExperimentPageGallery from "../components/ExperimentPageGallery";
import ReferencePageGallery from "../components/ReferencePageGallery";
import FeedbackForm from "../components/FeedbackForm";

const Editor = () => {
  // Color theme
  const { darkMode } = useContext(ThemeContext);

  // --- KEY INFORMATION ---
  // Main Experiment Data
  const [selectedExperiments, setSelectedExperiments] = useState([
    new Experiment(),
  ]);

  // Weighted experiment, if there additional related experiments
  const [averagedExperiment, setAveragedExperiment] = useState(
    new Experiment()
  );

  const [averagedExperimentCodex, setAveragedExperimentCodex] = useState(
    new Codex()
  );

  // Complete list of scribes
  const [scribes, setScribes] = useState([]);

  // --- SELECTED INFORMATION TO BE SHOWN ---
  // Selected Codex of the Main Experiment
  const [selectedExperimentCodecies, setSelectedExperimentCodecies] = useState(
    []
  );

  // Selected single Page from the Main Experiment's Codex
  const [selectedExperimentPages, setSelectedExperimentPages] = useState([
    new Page(),
  ]);

  // All pages of a selected Scribe
  const [referenceScribePages, setReferenceScribePages] = useState(
    new ScribePageCollection()
  );

  // Selected single Page from the selected Scribes's Pages
  const [selectedReferencePage, setSelectedReferencePage] = useState(
    new Page()
  );

  // All Comments for a selected Scribe
  const [selectedScribeComments, setSelectedScribeComments] = useState([]);

  // --- HELPER PARAMETERS ---
  // Two keypoints for grayscale visualization
  const [grayscalePoints, setGrayscalePoints] = useState([50, 80]);

  // Flag to toggle between page comparison layouts
  const [horizontalView, setHorizontalView] = useState(true);

  // --- DATA HANDLING FUNCTIONS ---
  // Setting up the initial page from the database information
  const SetupEditor = async (mainExperimentId) => {
    // Getting database info from API calls
    const mainExperimentData = await APIGetExperimentById(mainExperimentId);
    const relatedExperimentsData = await APIGetExperimentsByParentId(
      mainExperimentId
    );
    const scribesData = await APIGetScribes();
    const allPageCorrections = await APIGetAllPageCorrections();
    const allPageNotes = await APIGetAllPageNotes();

    // Variables for parsed data
    var parsedScribes = [];
    var parsedMainExperiment = new Experiment();
    var parsedRelatedExperiments = [];
    var parsedAllExperiments = [];
    var parsedAveragedExperiment = new Experiment();

    // Setting up Scribes
    try {
      // Parsing data
      scribesData.forEach((scribe) => {
        parsedScribes.push(
          new Scribe(
            scribe.id,
            scribe.name,
            scribe.backgroundColour,
            scribe.backgroundType,
            false
          )
        );
      });
      // Checking whether they appear in any relevant Experiments
      // Check for childExperiments too
      parsedScribes.forEach((scribe) => {
        if (
          mainExperimentData.experimentToPages.some(
            (entry) =>
              entry.scribe.id == scribe.id || entry.page.scribe.id == scribe.id
          ) ||
          relatedExperimentsData.some((relatedExperiment) =>
            relatedExperiment.experimentToPages.some(
              (entry) =>
                entry.scribe.id == scribe.id ||
                entry.page.scribe.id == scribe.id
            )
          )
        ) {
          scribe.inExperiment = true;
        }
      });
      // Sorting by name
      parsedScribes.sort((a, b) => {
        if (a.name.slice(0, 1) > b.name.slice(0, 1)) return 1;

        if (a.name.slice(0, 1) < b.name.slice(0, 1)) return -1;
        else {
          if (a.name.slice(2) > b.name.slice(2)) return 1;

          if (a.name.slice(2) < b.name.slice(2)) return -1;
          else return 0;
        }
      });

      setScribes(parsedScribes);
    } catch (error) {
      //console.log(error);
      Report.failure(
        "Error with loading scribes!",
        scribesData.statusCode +
          " - " +
          scribesData.error +
          ": " +
          scribesData.message,
        "OK"
      );
    }

    // Setting up MainExperiment
    try {
      parsedMainExperiment = GenerateParsedExperiment(
        mainExperimentData,
        parsedScribes,
        allPageCorrections,
        allPageNotes
      );
    } catch (error) {
      //console.log(error);
      Report.failure(
        "Error with loading main experiment!",
        mainExperimentData.statusCode +
          " - " +
          mainExperimentData.error +
          ": " +
          mainExperimentData.message,
        "OK"
      );
    }

    // Setting up possible ChildExperiments
    try {
      relatedExperimentsData.forEach((relatedExperimentData) => {
        parsedRelatedExperiments.push(
          GenerateParsedExperiment(
            relatedExperimentData,
            parsedScribes,
            allPageCorrections,
            allPageNotes
          )
        );
      });
    } catch (error) {
      //console.log(error);
      Report.failure(
        "Error with loading child experiments!",
        relatedExperimentsData.statusCode +
          " - " +
          relatedExperimentsData.error +
          ": " +
          relatedExperimentsData.message,
        "OK"
      );
    }

    // Pushing all experiments into a unified array
    parsedAllExperiments.push(parsedMainExperiment);
    parsedRelatedExperiments.forEach((parsedRelatedExperiment) => {
      parsedAllExperiments.push(parsedRelatedExperiment);
    });

    // Setting up averaged experiment
    if (parsedMainExperiment.id != "" && parsedAllExperiments.length > 1) {
      var averagedCodecies = [];
      var sumConfidence = 0;
      var numOfPages = 0;

      parsedMainExperiment.codecies.forEach((codex) => {
        var averagedCodexPages = [];

        codex.pages.forEach((page) => {
          var averagedPagePredictions = [];

          var predictionsForPage = GetAllPagePredictionsById(
            page.id,
            parsedAllExperiments
          );

          predictionsForPage.forEach((prediction) => {
            const averagedArrayIndex = averagedPagePredictions.findIndex(
              (averagedPagePrediction) =>
                averagedPagePrediction.predictionScribe.id ==
                prediction.predictionScribe.id
            );
            if (averagedArrayIndex != -1) {
              averagedPagePredictions[averagedArrayIndex].count += 1;
              averagedPagePredictions[averagedArrayIndex].probabilitySum +=
                parseFloat(prediction.probability);
            } else {
              averagedPagePredictions.push(
                new AveragedPagePrediction(
                  parseFloat(prediction.probability),
                  1,
                  prediction.predictionScribe
                )
              );
            }
          });

          averagedPagePredictions.sort((a, b) => {
            if (a.count > b.count) return -1;
            if (a.count < b.count) return 1;
            else {
              if (a.probabilitySum > b.probabilitySum) return -1;
              if (a.probabilitySum < b.probabilitySum) return 1;
              else return 0;
            }
          });

          numOfPages += 1;
          sumConfidence +=
            averagedPagePredictions[0].probabilitySum /
            averagedPagePredictions[0].count;

          averagedCodexPages.push(
            new Page(
              page.id,
              page.name,
              page.imgLink,
              page.prevLink,
              page.groundtruthScribe,
              page.codexId,
              page.codexName,
              new PageExperimentPrediction(
                averagedPagePredictions[0].probabilitySum /
                  averagedPagePredictions[0].count,
                averagedPagePredictions[0].predictionScribe
              ),
              page.corrections,
              page.notes
            )
          );
        });

        averagedCodecies.push(
          new Codex(codex.id, codex.name, averagedCodexPages)
        );
      });

      // Filtering for scribes that appear in the experiment
      const predictionScribes = FilterScribes(averagedCodecies);
      // Id of most frequently appearing prediction scribe
      const mostFrequentScribeId = GetMostFrequentScribeId(averagedCodecies);

      parsedAveragedExperiment = new Experiment(
        "averaged",
        "",
        "Averaged Experiment",
        "Avereaged values from all relevant codecies.",
        (sumConfidence / numOfPages).toFixed(2),
        parsedScribes.find((scribe) => scribe.id == mostFrequentScribeId),
        predictionScribes,
        averagedCodecies
      );

      setAveragedExperiment(parsedAveragedExperiment);
    }

    setSelectedExperiments(parsedAllExperiments);

    Loading.remove();
  };

  // Selecting Experiment Codex by Id for both Main and Child Experiments
  const SelectExperimentCodex = (codexId) => {
    var codeciesToSelect = [];

    selectedExperiments.forEach((experiment) => {
      const codexToSelect = experiment.codecies.find(
        (codex) => codex.id == codexId
      );

      if (codeciesToSelect != undefined) {
        codeciesToSelect.push(codexToSelect);
      }
    });

    const codexToSelect = averagedExperiment.codecies.find(
      (codex) => codex.id == codexId
    );

    if (codexToSelect != undefined) {
      setAveragedExperimentCodex(codexToSelect);
    }

    if (codeciesToSelect.length == selectedExperiments.length) {
      setSelectedExperimentCodecies(codeciesToSelect);
    } else {
      setSelectedExperimentCodecies([new Codex()]);
      Report.failure("Requested codex could not be found!", "Invalid codexId");
    }
  };

  // Selecting Experiment Page by Id
  const SelectExperimentPage = (pageId) => {
    var experimentPagesToSelect = [];

    // Child experiments
    selectedExperiments.forEach((experiment) => {
      experiment.codecies.forEach((codex) => {
        const searchResult = codex.pages.find((page) => page.id == pageId);

        if (searchResult != undefined) {
          experimentPagesToSelect.push(searchResult);
        }
      });
    });

    if (experimentPagesToSelect.length == selectedExperiments.length) {
      setSelectedExperimentPages(experimentPagesToSelect);
    } else {
      setSelectedExperimentPages([new Page()]);
      Report.failure("Requested page could not be found!", "Invalid pageId");
    }
  };

  // Calling and sorting pages for reference Scribe
  const SelectReferenceScribe = async (scribeId) => {
    const scribePagesData = await APIGetPagesByScribeId(scribeId);

    const referenceScribe = scribes.find((scribe) => scribe.id == scribeId);

    try {
      var parsedScribeCodecies = GetReferenceCodecies(scribePagesData);

      parsedScribeCodecies.forEach((codex) => {
        var pages = [];

        scribePagesData.forEach((entry) => {
          if (entry.codex.id == codex.id) {
            pages.push(
              new Page(
                entry.id,
                entry.pageNumber,
                "https://scribe-data.neuronalresearch.media.fhstp.ac.at/" +
                  entry.path +
                  entry.fileName,
                "https://scribe-data.neuronalresearch.media.fhstp.ac.at/" +
                  entry.pathThumbnail +
                  entry.fileName,
                scribes.find((scribe) => scribe.id == scribeId),
                entry.codex.id,
                entry.codex.name,
                null
              )
            );
          }
        });

        // Sorting filtered pages based on page-number
        pages.sort((a, b) => {
          if (a.name.slice(0, 3) > b.name.slice(0, 3)) return 1;
          if (a.name.slice(0, 3) < b.name.slice(0, 3)) return -1;
          else {
            if (a.name.slice(3) < b.name.slice(3)) return 1;
            if (a.name.slice(3) > b.name.slice(3)) return -1;
            else return 0;
          }
        });

        codex.pages = pages;
      });

      setReferenceScribePages(
        new ScribePageCollection(referenceScribe, parsedScribeCodecies)
      );
    } catch (error) {
      //console.log(error);
      Report.failure(
        "Error with loading Reference Pages!",
        scribePagesData.statusCode +
          " - " +
          scribePagesData.error +
          ": " +
          scribePagesData.message,
        "OK"
      );
    }
  };

  // Selecting Reference Page by Id
  const SelectReferencePage = (pageId) => {
    var pageToSelect = undefined;

    referenceScribePages.codecies.forEach((codex) => {
      const searchResult = codex.pages.find((page) => page.id == pageId);

      if (searchResult != undefined) {
        pageToSelect = searchResult;
      }
    });

    if (pageToSelect) {
      setSelectedReferencePage(pageToSelect);
    } else {
      setSelectedReferencePage(new Page());
      Report.failure("Requested page could not be found!", "Invalid pageId");
    }
  };

  // Calling and sorting comments by Scribe Id
  const SelectCommentScribe = async (scribeId) => {
    const scribeCommentData = await APIGetCommentsByScribeId(scribeId);

    var parsedComments = [];

    try {
      scribeCommentData.notes.map((entry) => {
        parsedComments.push(
          new ScribeComment(
            entry.note,
            entry.created_at.slice(0, 10),
            entry.created_at.slice(11, 19),
            new Corrector(
              entry.corrector.id,
              entry.corrector.name,
              entry.corrector.role
            )
          )
        );
      });
      setSelectedScribeComments(parsedComments);
    } catch (error) {
      //console.log(error);
      Report.failure(
        "Error with loading Scribe Comments!",
        scribeCommentData.statusCode +
          " - " +
          scribeCommentData.error +
          ": " +
          scribeCommentData.message,
        "OK"
      );
    }
  };

  // Adjusting points for grayscale coloring
  const ChangeGrayScalePoints = (point1, point2) => {
    setGrayscalePoints([point1, point2]);
  };

  // --- UPLOAD FUNCTIONS ---
  const SavePageCorrection = async (experimentPageId, corrections) => {
    const response = await APIUploadPageCorrection(
      experimentPageId,
      "Correction",
      localStorage.getItem("userId"),
      localStorage.getItem("experimentId"),
      corrections
    );

    if (response.statusCode == undefined) {
      Report.success("Upload Success!", "Thank you for feedback!", "OK");
      SetupEditor(localStorage.getItem("experimentId"));
    } else {
      Report.failure(
        "Upload Error!",
        response.statusCode + " - " + response.error + ": " + response.message,
        "OK"
      );
    }
  };

  const SavePageNote = async (experimentPageId, referencePageId, note) => {
    var pages = [];

    if (experimentPageId) pages.push(experimentPageId);
    if (referencePageId) pages.push(referencePageId);

    const response = await APIUploadPageNote(
      pages,
      note,
      localStorage.getItem("userId")
    );

    if (response.statusCode == undefined) {
      Report.success("Upload Success!", "Thank you for feedback!", "OK");
      SetupEditor(localStorage.getItem("experimentId"));
    } else {
      Report.failure(
        "Upload Error!",
        response.statusCode + " - " + response.error + ": " + response.message,
        "OK"
      );
    }
  };

  const SaveScribeComment = async (scribeId, comment) => {
    const response = await APIUploadComment(scribeId, comment);

    if (response.statusCode == undefined) {
      Report.success("Upload Success!", "Thank you for feedback!", "OK");
      SetupEditor(localStorage.getItem("experimentId"));
    } else {
      Report.failure(
        "Upload Error!",
        response.statusCode + " - " + response.error + ": " + response.message,
        "OK"
      );
    }
  };

  const CreateScribe = async (scribeName) => {
    const response = await APICreateScribe(scribeName);

    if (response.statusCode == undefined) {
      Report.success("Upload Success!", "Thank you for feedback!", "OK");
      SetupEditor(localStorage.getItem("experimentId"));
    } else {
      Report.failure(
        "Upload Error!",
        response.statusCode + " - " + response.error + ": " + response.message,
        "OK"
      );
    }
  };

  // --- SUPPORT FUNCTIONS ---
  // Filtering raw API data to get all codecies in the experiment
  const GetExperimentCodecies = (rawExperimentPages) => {
    // List for codecies
    var listOfCodexes = [];

    // Finding every codex in the current experiment
    rawExperimentPages.forEach((experiment) => {
      if (!listOfCodexes.some((o) => o.id == experiment.page.codex.id)) {
        listOfCodexes.push(
          new Codex(experiment.page.codex.id, experiment.page.codex.name, [])
        );
      }
    });

    // Ordering list by codex name
    listOfCodexes.sort((a, b) => {
      return a.name.slice(4) - b.name.slice(4);
    });

    return listOfCodexes;
  };

  const GetReferenceCodecies = (rawReferencePages) => {
    // List for codecies
    var listOfCodexes = [];

    // Finding every codex in the current experiment
    rawReferencePages.forEach((reference) => {
      if (!listOfCodexes.some((o) => o.id == reference.codex.id)) {
        listOfCodexes.push(
          new Codex(reference.codex.id, reference.codex.name, [])
        );
      }
    });

    // Ordering list by codex name
    listOfCodexes.sort((a, b) => {
      return a.name.slice(4) - b.name.slice(4);
    });

    return listOfCodexes;
  };

  // Returns array of PageExperimentPredictions from all given Experiments by selected Id
  const GetAllPagePredictionsById = (pageId, allExperiments) => {
    var experimentPagePredictionsToSelect = [];

    // Child experiments
    allExperiments.forEach((experiment) => {
      experiment.codecies.forEach((codex) => {
        const searchResultPage = codex.pages.find((page) => page.id == pageId);

        if (searchResultPage != undefined) {
          experimentPagePredictionsToSelect.push(
            searchResultPage.experimentPrediction
          );
        }
      });
    });

    return experimentPagePredictionsToSelect;
  };

  const GenerateParsedExperiment = (
    experimentData,
    parsedScribes,
    allPageCorrections,
    allPageNotes
  ) => {
    // Oredered list of codecies in the experiment
    var codexList = [];

    // Variables for calculating Experiment average
    var sumConfidence = 0;
    var numOfPages = 0;

    // Grouping data based on codecies in the experiment
    const codeciesInExperiment = GetExperimentCodecies(
      experimentData.experimentToPages
    );

    // Putting pages into their respective codecies
    codeciesInExperiment.forEach((codex) => {
      var pages = [];
      experimentData.experimentToPages.forEach((entry) => {
        if (entry.page.codex.id == codex.id) {
          // Finding relevant PageCorrections and PageNotes
          var relevantPageCorrections = [];
          var relevantPageNotes = [];

          // Filter corrections and notes
          allPageCorrections.forEach((correction) => {
            if (correction.page.id == entry.page.id) {
              var individualCorrections = [];

              correction.correctionsToScribes.forEach((correctionEntry) => {
                individualCorrections.push(
                  new IndividualCorrection(
                    parsedScribes.find(
                      (scribe) => scribe.id == correctionEntry.scribe.id
                    ),
                    correctionEntry.confidence
                  )
                );
              });

              relevantPageCorrections.push(
                new PageCorrection(
                  individualCorrections,
                  correction.created_at.slice(0, 10),
                  correction.created_at.slice(11, 19),
                  new Corrector()
                )
              );
            }
          });
          allPageNotes.forEach((note) => {
            if (note.pages.some((page) => page.id == entry.page.id)) {
              relevantPageNotes.push(
                new PageNote(
                  note.note,
                  note.created_at.slice(0, 10),
                  note.created_at.slice(11, 19),
                  new Corrector(
                    note.corrector.id,
                    note.corrector.name,
                    note.corrector.role
                  )
                )
              );
            }
          });

          // Creating Page
          pages.push(
            new Page(
              entry.page.id,
              entry.page.pageNumber,
              "https://scribe-data.neuronalresearch.media.fhstp.ac.at/" +
                entry.page.path +
                entry.page.fileName,
              "https://scribe-data.neuronalresearch.media.fhstp.ac.at/" +
                entry.page.pathThumbnail +
                entry.page.fileName,
              parsedScribes.find((scribe) => scribe.id == entry.page.scribe.id),
              entry.page.codex.id,
              entry.page.codex.name,
              new PageExperimentPrediction(
                (entry.probability * 100).toFixed(2),
                parsedScribes.find((scribe) => scribe.id == entry.scribe.id)
              ),
              relevantPageCorrections,
              relevantPageNotes
            )
          );
        }
      });

      // Sorting filtered pages based on page-number
      pages.sort((a, b) => {
        if (a.name.slice(0, 3) > b.name.slice(0, 3)) return 1;
        if (a.name.slice(0, 3) < b.name.slice(0, 3)) return -1;
        else {
          if (a.name.slice(3) < b.name.slice(3)) return -1;
          if (a.name.slice(3) > b.name.slice(3)) return 1;
          else return 0;
        }
      });

      // Calculating for average values
      sumConfidence = pages.reduce(
        (total, next) => total + Number(next.experimentPrediction.probability),
        0
      );
      numOfPages += pages.length;

      codexList.push(new Codex(codex.id, codex.name, pages));
    });

    // Filtering for scribes that appear in the experiment
    const predictionScribes = FilterScribes(codexList);
    // Id of most frequently appearing prediction scribe
    const mostFrequentScribeId = GetMostFrequentScribeId(codexList);

    // Finalizing data from experiment
    return new Experiment(
      experimentData.id,
      "",
      experimentData.title,
      experimentData.note,
      (sumConfidence / numOfPages).toFixed(2),
      parsedScribes.find((scribe) => scribe.id == mostFrequentScribeId),
      predictionScribes,
      codexList
    );
  };

  // Finding scribes, that appear on the pages and calculating their average confidence
  const FilterScribes = (experimentCodecies) => {
    var predictionScribeList = [];

    // Detecting all unique scribes in the experiment
    experimentCodecies.forEach((codex) => {
      codex.pages.forEach((page) => {
        if (page.experimentPrediction) {
          if (
            !predictionScribeList.some(
              (o) => o.id == page.experimentPrediction.predictionScribe.id
            )
          ) {
            predictionScribeList.push(
              new PredictionScribe(
                page.experimentPrediction.predictionScribe.id,
                page.experimentPrediction.predictionScribe.name,
                page.experimentPrediction.predictionScribe.backgroundColour,
                page.experimentPrediction.predictionScribe.backgroundType,
                0,
                0
              )
            );
          }
        }
      });
    });

    // Sorting scribes
    predictionScribeList.sort((a, b) => {
      if (a.name.slice(0, 1) > b.name.slice(0, 1)) return 1;

      if (a.name.slice(0, 1) < b.name.slice(0, 1)) return -1;
      else {
        if (a.name.slice(2) > b.name.slice(2)) return 1;

        if (a.name.slice(2) < b.name.slice(2)) return -1;
        else return 0;
      }
    });

    // Averaging for confidenceScribes
    predictionScribeList.forEach((predictionScribe) => {
      var itemCount = 0;
      var itemSum = 0;

      experimentCodecies.forEach((codex) => {
        codex.pages.forEach((page) => {
          if (page.experimentPrediction) {
            if (
              page.experimentPrediction.predictionScribe.id ==
              predictionScribe.id
            ) {
              itemCount++;
              itemSum += Number(page.experimentPrediction.probability);
            }
          }
        });
      });

      predictionScribe.averageConfidence = (itemSum / itemCount).toFixed(2);
      predictionScribe.count = itemCount;
    });

    return predictionScribeList;
  };

  // Finding the scribe with the highest occurance rate
  const GetMostFrequentScribeId = (experimentCodecies) => {
    var detectedScribeIds = [];

    // Looping over all
    experimentCodecies.forEach((codex) => {
      codex.pages.forEach((page) => {
        if (page.experimentPrediction) {
          detectedScribeIds.push(page.experimentPrediction.predictionScribe.id);
        }
      });
    });

    function getMostFrequent(arr) {
      const hashmap = arr.reduce((acc, val) => {
        acc[val] = (acc[val] || 0) + 1;
        return acc;
      }, {});
      return Object.keys(hashmap).reduce((a, b) =>
        hashmap[a] > hashmap[b] ? a : b
      );
    }

    // Returning complete list of scribe Ids
    return getMostFrequent(detectedScribeIds);
  };

  useEffect(() => {
    // Loading screen
    Loading.standard();
    try {
      // Loading data
      SetupEditor(localStorage.getItem("experimentId"));
    } catch (err) {
      Loading.remove();
      Report.failure("Database Error", "Unable to load from database.", "OK");
    }
  }, []);

  return (
    <Container
      fluid
      className="py-4"
      style={{ backgroundColor: darkMode && "gray" }}
    >
      {/*--- Legends ---*/}
      <Row className="mb-1">
        <LegendDisplay
          scribes={scribes}
          grayscalePoints={grayscalePoints}
          changeGrayScalePoints={ChangeGrayScalePoints}
        />
      </Row>

      {/*--- Navigation Grids ---*/}
      <Row>
        <NavigationGrid
          selectedExperiments={selectedExperiments}
          selectedExperimentCodecies={selectedExperimentCodecies}
          averagedExperiment={averagedExperiment}
          averagedExperimentCodex={averagedExperimentCodex}
          grayscalePoints={grayscalePoints}
          selectExperimentPage={SelectExperimentPage}
        />
      </Row>

      {/*--- Experiment Page Gallery ---*/}
      <Row>
        <ExperimentPageGallery
          title={"Codecies in experiment"}
          tooltip={"Pages available in the experiment, grouped by codecies."}
          selectedExperiments={selectedExperiments}
          selectedExperimentCodecies={selectedExperimentCodecies}
          grayscalePoints={grayscalePoints}
          selectExperimentCodex={SelectExperimentCodex}
          selectExperimentPage={SelectExperimentPage}
        />
      </Row>

      <Row className="mb-2" id="image-viewer">
        {/*--- Image Displays ---*/}
        <Col>
          <div
            style={{
              display: !horizontalView && "flex",
            }}
          >
            <div
              className="mb-1 exp-img-view"
              style={{ width: !horizontalView && "49%" }}
            >
              <ImageDisplay
                title={"EXPERIMENT PAGE"}
                tooltip={"Selected experiment image for feedback."}
                selectedExperiments={selectedExperiments}
                experimentPages={selectedExperimentPages}
                referencePage={new Page()}
                loaded={() => Block.remove(".exp-img-view")}
                grayscalePoints={grayscalePoints}
                scrollStep={50}
                isHorizontal={horizontalView}
                switchView={() => setHorizontalView(!horizontalView)}
              />
            </div>
            <div style={{ width: !horizontalView && "2%" }}></div>
            <div
              className="ref-img-view"
              style={{ width: !horizontalView && "49%" }}
            >
              <ImageDisplay
                title={"REFERENCE PAGE"}
                tooltip={"Selected reference image for comparison."}
                referencePage={selectedReferencePage}
                loaded={() => Block.remove(".ref-img-view")}
                scrollStep={50}
                isHorizontal={horizontalView}
                switchView={() => setHorizontalView(!horizontalView)}
              />
            </div>
          </div>
        </Col>
        <Col lg={15} xl={4} xxl={3}>
          {/*--- Feedback Area ---*/}
          <FeedbackForm
            experimentPages={selectedExperimentPages}
            referencePage={selectedReferencePage}
            scribes={scribes}
            scribeComments={selectedScribeComments}
            getScribeComments={SelectCommentScribe}
            savePageCorrection={SavePageCorrection}
            savePageNote={SavePageNote}
            saveScribeComment={SaveScribeComment}
            createScribe={CreateScribe}
          />
        </Col>
      </Row>

      <Row>
        <ReferencePageGallery
          title={"References for selected scribe"}
          tooltip={"Reference pages for comparison, grouped by scribes."}
          referenceScribePages={referenceScribePages}
          scribes={scribes}
          selectReferenceScribe={SelectReferenceScribe}
          selectReferencePage={SelectReferencePage}
        />
      </Row>
    </Container>
  );
};

export default Editor;
