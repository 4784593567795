// Variable handling
import React, { useEffect, useState } from "react";
// Route handling
import { Route, Redirect } from "react-router-dom";

import { APITestConnection } from "../utilities/apiCalls";

export default function PrivateRoute({ children, ...rest }) {
  // Status of server-check
  const [statusCode, setStatusCode] = useState(0);
  // Flag for preventing premature rendering
  const [isLoading, setIsLoading] = useState(true);

  // Checking on server for valid session
  const fetchData = async () => {
    try {
      setStatusCode(await APITestConnection());
      setIsLoading(false);
    } catch (e) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return isLoading ? null : (
    <Route
      {...rest}
      render={({ location }) => {
        return localStorage.getItem("userLoggedIn") == "true" &&
          statusCode == 200 ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
