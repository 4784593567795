import React from "react";
// Tooltip component
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// Icon
import { FaInfoCircle } from "react-icons/fa";

// Params: text to be displayed on hover
const Info = ({ text }) => {
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="top"
      overlay={<Tooltip>{text}</Tooltip>}
    >
      <div style={{ display: "inline-block" }}>
        <FaInfoCircle />
      </div>
    </OverlayTrigger>
  );
};

export default Info;
