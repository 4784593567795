import React, { useState, useEffect } from "react";

// Pages to render on the website
import Home from "./pages/Home";
import Experiments from "./pages/Experiments";
import Editor from "./pages/Editor";

// Special rerouting for not logged-in users
import PrivateRoute from "./components/PrivateRoute";

// React bootstrap components
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

// Context API for user details
import { ExperimentContext } from "./context/ExperimentContext";
// Context API for website theme (Dark / Light)
import { ThemeContext } from "./context/ThemeContext";

// Icons
import { MdOutlineLightMode } from "react-icons/md";
import { MdOutlineDarkMode } from "react-icons/md";
import { CgProfile } from "react-icons/cg";

// Navigation components
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
// Countdown component
import Countdown from "./components/Countdown";

// API calls
import { APILogout } from "./utilities/apiCalls";

const App = () => {
  // "Error variable"
  const [error, setError] = useState("");

  // Variable to update the navbar
  const [updateFlag, setUpdateFlag] = useState(false);

  // Darkmode variable
  const [darkMode, setDarkMode] = useState(true);

  // Changing theme (Dark / Light)
  const changeTheme = () => {
    const changed = !darkMode;
    setDarkMode(changed);
  };

  // Logout function
  const LogoutFunct = async () => {
    await APILogout();

    // Setting login to false
    localStorage.setItem("userLoggedIn", false);

    updateNav();
  };

  // Update function
  const updateNav = () => {
    setUpdateFlag(!updateFlag);
  };

  // Refreshing component on updating flag
  useEffect(() => {}, [updateFlag]);

  return (
    <div className="App">
      {/* Darkmode context-API */}
      <ThemeContext.Provider value={{ darkMode }}>
        {/* Update context-API */}
        <ExperimentContext.Provider value={{ updateNav }}>
          <Router>
            <div className="mt-5">
              <Navbar bg="dark" variant="dark" fixed="top">
                <Container fluid>
                  {/* Title */}
                  <Navbar.Brand>ScribeID AI</Navbar.Brand>

                  {/* Navbar items */}
                  <Nav className="me-auto">
                    {/* Experiments */}
                    {localStorage.getItem("userLoggedIn") == "true" && (
                      <Nav.Link as={Link} to="/">
                        Experiments
                      </Nav.Link>
                    )}
                    {/* Darkmode toggle */}
                    <Nav.Link onClick={() => changeTheme()}>
                      {darkMode ? (
                        <MdOutlineLightMode size={"20px"} />
                      ) : (
                        <MdOutlineDarkMode size={"20px"} />
                      )}
                    </Nav.Link>
                  </Nav>
                  <Nav>
                    {/* Version display */}
                    <Navbar.Text>Version date: 2023-01-24</Navbar.Text>
                    {/* Countdown timer */}
                    {localStorage.getItem("userLoggedIn") == "true" && (
                      <Nav.Item>
                        <Nav.Link eventKey="none" disabled>
                          <Countdown logoutFunc={LogoutFunct} />
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {/* Account dropdown */}
                    {localStorage.getItem("userLoggedIn") == "true" && (
                      <NavDropdown
                        className="me-4"
                        title={
                          <>
                            <CgProfile size={"25px"} />{" "}
                            {localStorage.getItem("userName")}
                          </>
                        }
                      >
                        <NavDropdown.Item
                          as={Link}
                          to="/login"
                          onClick={() => LogoutFunct()}
                        >
                          Log out
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                  </Nav>
                </Container>
              </Navbar>

              {/* Router */}
              <Switch>
                <PrivateRoute exact path="/">
                  <Experiments />
                </PrivateRoute>
                <Route path="/login">
                  <Home error={error} />
                </Route>
                <PrivateRoute path="/editor">
                  <Editor />
                </PrivateRoute>
              </Switch>
            </div>
          </Router>
        </ExperimentContext.Provider>
      </ThemeContext.Provider>
    </div>
  );
};

export default App;
